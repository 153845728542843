import { createSlice } from '@reduxjs/toolkit';

const contextSlice = createSlice({
  name: 'context',
  initialState: {
    sendWithContextFiles: [],
    activeContextFile: "",
    activeContext: '',
    contextSubmenu: false,
  },
  reducers: {
    setSendWithContextFiles: (state, action) => {
      state.sendWithContextFiles = action.payload;
    },
    setActiveContextFile: (state, action) => {
      state.activeContextFile = action.payload;
    },
    setActiveContext: (state, action) => {
      state.activeContext = action.payload;
    },
    setContextSubmenu: (state, action) => {
      state.contextSubmenu = action.payload;
    },
  },
});

export const {
  setSendWithContextFiles,
  setActiveContextFile,
  setActiveContext,
  setContextSubmenu,
} = contextSlice.actions;
export default contextSlice.reducer;
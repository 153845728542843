import { createSlice } from '@reduxjs/toolkit';

const googleSlice = createSlice({
  name: 'google',
  initialState: {
    isSignedIn: false,
    googleData: null,
    googleFileNames: [],
    acccessToken: null,
  },
  reducers: {
    setGoogleData: (state, action) => {
      state.googleData = action.payload;
    },
    setGoogleFileNames: (state, action) => {
      state.googleFileNames = action.payload;
    },
    addGoogleFileName: (state, action) => {
      state.googleFileNames.push(action.payload);
    },
    deleteGoogleFileName: (state, action) => {
      state.googleFileNames = state.googleFileNames.filter(fileName => fileName !== action.payload);
    },
    signIn: (state, action) => {
      state.isSignedIn = true;
      state.acccessToken = action.payload;
    },
    signOut: (state) => {
      state.isSignedIn = false;
      state.acccessToken = null;
    },
  },
});

export const { setGoogleData, setGoogleFileNames, addGoogleFileName, deleteGoogleFileName, signIn, signOut } = googleSlice.actions;
export default googleSlice.reducer;
import React from 'react';
import './style/chip.css';
import { googleSheetsLogo } from "../../assets/icons/googleSheetsLogo";
import { hubspotLogo } from "../../assets/icons/hubspotLogo";

const Chip = (props) => {
    const { startIcon, iconStyle, label, onClick, type, active, tooltip, hubspot, google, labelStyle, subtitle } = props;
    const chipClassName = `chip ${active && "active"} ${type}`;

    return (
        <button title={tooltip} className={chipClassName} onClick={onClick}>
            {startIcon && <i className={`material-icons-outlined`} style={{ color: iconStyle }}>{startIcon}</i>}
            {google && <i className="material-icons-outlined sheets"> {googleSheetsLogo} </i>}
            {hubspot && <i className="material-icons-outlined hubspot"> {hubspotLogo} </i>}
            {(label || subtitle) && <div className="label-container">
                {label && <div className="label" style={labelStyle}>{label}</div>}
                {subtitle && <div className="subtitle" >{subtitle}</div>}
            </div>}
        </button>
    );
};

export default Chip;
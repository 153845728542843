import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../style/popup.css';
import { closePopup } from '../Popup';
import Button from '../../Button/Button';
import { toggleSnackbar } from '../../Snackbar/Snackbar';
import { handleFeedback } from "../../../api/Feedback";
import { setSelectedFiles, setFeedback, setIsPopupOpen } from '../../../state/slices/popupSlice';

export function ThumbsPopup(props) {
    const { popupThumbsUp } = props;
    const dispatch = useDispatch();
    const { isPopupOpen, popupClass, selectedFiles, feedback } = useSelector(state => state.popup);
    const { messages } = useSelector(state => state.messages);
    const { uploadedFileNames } = useSelector(state => state.uploadedFiles);
    const { googleFileNames } = useSelector(state => state.google);
    const { formattedHubspotSources } = useSelector(state => state.hubspot);

    function toggleFileSelection(filename) {
        selectedFiles.includes(filename)
            ? dispatch(setSelectedFiles(selectedFiles.filter(file => file !== filename)))
            : dispatch(setSelectedFiles([...selectedFiles, filename]));
    }

    const sendThumbs = () => {
        if (feedback !== "") {
            toggleSnackbar(dispatch, "info", "Sending feedback...");
            handleFeedback({
                feedback: feedback,
                thumbsUp: popupThumbsUp,
                selectedFiles: selectedFiles,
                chatHistory: messages
            })
                .then(response => {
                    if (response) {
                        toggleSnackbar(dispatch, "complete", "Feedback sent successfully!");
                    } else {
                        toggleSnackbar(dispatch, "error", "Feedback failed to send. Please try again later.");
                    }
                })
                .catch(error => {
                    console.error('Failed to send feedback:', error);
                    toggleSnackbar(dispatch, "error", "Feedback failed to send. Please try again later.");
                });
            closePopup(dispatch);
        } else {
            closePopup(dispatch);
        };
    };

    function renderThumbFiles() {
        const uploadedFiles = uploadedFileNames.map((filename, index) => (
            <Button
                active={selectedFiles.includes(filename)}
                key={"file" + index}
                startIcon={'description'}
                type={'medium'}
                label={filename}
                tooltip={filename}
                className={`button medium`}
                onClick={() => toggleFileSelection(filename)}
            />
        ));

        const googleFiles = googleFileNames.map((filename, index) => {
            const displayName = filename.startsWith("googlesheets_") ? filename.replace("googlesheets_", "").replace("_", " ").replace(".csv", "") : filename;
            const [displaySheetName, displayTabName] = displayName.split('-MAESTERTABNAME-');
            return (
                <Button
                    google
                    active={selectedFiles.includes(filename)}
                    key={"file" + index}
                    type={'medium'}
                    label={displaySheetName}
                    subtitle={displayTabName}
                    tooltip={displaySheetName}
                    className={`button medium`}
                    onClick={() => toggleFileSelection(filename)}
                />
            );
        });

        const hubspotFiles = formattedHubspotSources.map((filename, index) => {
            const displayName = filename.replace("hubspot_", "").replace(".csv", "").replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase()) + " Data Source";
            return (
                <Button
                    hubspot
                    active={selectedFiles.includes(filename)}
                    key={"file" + index}
                    type={'medium'}
                    label={displayName}
                    tooltip={displayName}
                    className={`button medium`}
                    onClick={() => toggleFileSelection(filename)}
                />
            )
        });

        return [uploadedFiles, googleFiles, hubspotFiles];
    }

    return (
        <div className={`popup thumbs ${popupThumbsUp && "up"} ${!isPopupOpen && "hidden"}`} onClick={(e) => e.stopPropagation()}>
            <div className="header">
                <div className="title"> Thank you for your feedback! </div>
            </div>
            <div className="content">
                <textarea
                    className="text-input"
                    placeholder={popupThumbsUp ? 'What did Maester do well?' : 'What can Maester do better?'}
                    value={feedback}
                    onChange={(e) => dispatch(setFeedback(e.target.value))}
                />
                {!popupThumbsUp && [uploadedFileNames, googleFileNames, formattedHubspotSources].length > 0 && (
                    <>
                        <div className="title"> What should have been used to answer your question? (Optional) </div>
                        <div className="container">
                            {renderThumbFiles()}
                        </div>
                    </>
                )}
            </div>
            <div className="popup-footer">
                <Button type="action" label={"Submit"} startIcon={"check"} onClick={sendThumbs} />
            </div>
        </div>
    );
}
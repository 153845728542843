import { put, post } from 'aws-amplify/api';
import { getJwtToken } from "../../functions/useJwtToken"

export const getHubSpotAuthCodeAndExport = async (hubspotCode) => {
  const jwtToken = await getJwtToken();
  if (!jwtToken) {
    console.error("JWT Token Fetch Error")
    return null
  }
  if (hubspotCode) {
    console.log('hubspot code:', hubspotCode)
    try {
      const response = await post({
        apiName: 'MaesterV3API',
        path: '/api/v3/hubspot/auth',
        options: {
          headers: { Authorization: jwtToken },
          body: {
            hubspotCode: hubspotCode,
            redirect_uri: window.location.origin + '/hubspot-callback'
          }
        }
      }).response;
      console.log(response);

      const export_response = await put({
        apiName: 'MaesterV3API',
        path: '/api/v3/hubspot/direct-export',
        options: {
          headers: { Authorization: jwtToken },
          body: {}
        }
      }).response;
      console.log(export_response);
    } catch (error) {
      console.error("Failed to save HubSpot auth code", error);
    }
  }
}


import { post, put } from 'aws-amplify/api';
import { getFileNames } from '../Files';
import { getJwtToken } from "../../functions/useJwtToken"

export const addSheet = async (googleData) => {
  const jwtToken = await getJwtToken();
  if (!jwtToken) {
    console.error("JWT Token Fetch Error")
    return null
  }
  const data = googleData.data;
  const file = data.docs[0];
  try {
    const response = await put({
      apiName: 'MaesterV3API',
      path: '/api/v3/google/manage-sheets',
      options: {
        headers: { Authorization: jwtToken },
        body: {
          file_id: file.id,
          file_name: file.name,
          last_updated: Date.now(),
          drive_last_modified: file.lastEditedUtc,
          tab_names: googleData.selectedTabNames,
        }
      }
    }).response;
    if (response) {
      console.log(`File ${file.name} updated successfully in database.`);
      console.log("addSheet response", response);
      return true;
    }
  } catch (error) {
    console.error("update sheet failed", error);
    return false;
  }
  return true;
}



export const refreshSheets = async () => {
  const jwtToken = await getJwtToken();
  if (!jwtToken) {
    console.error("JWT Token Fetch Error")
    return null
  }
  try {
    const responseBody = await (await post({
      apiName: 'MaesterV3API',
      path: '/api/v3/google/manage-sheets',
      options: {
        headers: { Authorization: jwtToken },
        body: {}
      }
    }).response).body.json();
    if (responseBody) {
      let allFiles = await getFileNames();
      return allFiles[1];
    }
  } catch (error) {
    console.error("error refreshing sheets", error);
    return null;
  }
}
import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    snackbarContent: '',
    snackbarStatus: 'hidden',
    snackbarTimeout: null,
  },
  reducers: {
    setSnackbarContent: (state, action) => {
      state.snackbarContent = action.payload;
    },
    setSnackbarStatus: (state, action) => {
      state.snackbarStatus = action.payload;
    },
    setSnackbarTimeout: (state, action) => {
      state.snackbarTimeout = action.payload;
    },
    clearSnackbarTimeout(state) {
      if (state.snackbarTimeout) {
        clearTimeout(state.snackbarTimeout);
        state.snackbarTimeout = null;
      }
    },
  },
});

export const { setSnackbarContent, setSnackbarStatus, setSnackbarTimeout, clearSnackbarTimeout } = snackbarSlice.actions;
export default snackbarSlice.reducer;
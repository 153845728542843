import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import AssistantMessage from './AssistantMessage/AssistantMessage';
import UserMessage from './UserMessage/UserMessage';
import DataRefreshMessage from './DataRefreshMessage/DataRefreshMessage';

const Message = forwardRef((props, ref) => {
    const { messageObject, showHeader } = props;
    const { role } = messageObject;
    const { user } = useSelector(state => state.utilities);
    switch (role) {
        case "assistant":
            return <>
                {messageObject.since_refreshed_files && ref && <DataRefreshMessage refreshedFiles={messageObject.since_refreshed_files} />}
                <AssistantMessage messageObject={messageObject} showHeader={showHeader} ref={ref} />
            </>;
        case "user":
            return <>
                {messageObject.since_refreshed_files && ref && <DataRefreshMessage refreshedFiles={messageObject.since_refreshed_files} />}
                <UserMessage messageObject={messageObject} showHeader={showHeader} user={user} ref={ref} />
            </>;

        default:
            return null;
    }
});

export default Message;

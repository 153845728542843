export const trendingTopics =
{
    "Sales Meetings": {
        "Meetings last 30 days": [
            "How many meetings were booked in the last 30 days?"
        ],
        "Weekly meetings": [
            "Create a chart showing the number of meetings booked per week for the last 6 weeks"
        ],
        "Meetings per rep": [
            "Create a chart showing the number of meetings booked per rep for the last 6 weeks"
        ],
        "Meetings this year": [
            "How many meetings were booked this year?"
        ],
        "Meetings trend": [
            "Create a chart showing the number of meetings booked per month for the last 6 months."
        ],
        "Recent meeting": [
            "What was the most recent meeting booked by the team?"
        ],
        "Most meetings": [
            "Who booked the most meetings this year?",
            "Who booked the most meetings this month?"
        ],
        "Fewest meetings": [
            "Who booked the fewest meetings this year?"
        ]
    },
    "Sales Emails": {
        "Emails last 30 days": [
            "How many emails were booked in the last 30 days?"
        ],
        "Weekly emails": [
            "Create a chart showing the number of emails booked per week for the last 6 weeks"
        ],
        "Emails per rep": [
            "Create a chart showing the number of emails booked per rep for the last 6 weeks"
        ],
        "Emails this year": [
            "How many emails were booked this year?"
        ],
        "Emails trend": [
            "Create a chart showing the number of emails booked per month for the last 6 months."
        ],
        "Recent email": [
            "What was the most recent email booked by the team?"
        ],
        "Most emails": [
            "Who booked the most emails this year?",
            "Who booked the most emails this month?"
        ],
        "Fewest emails": [
            "Who booked the fewest emails this year?"
        ]
    },
    "Revenue Trends": {
        "Monthly revenue": [
            "Create a chart showing the monthly revenue for this year."
        ],
        "Revenue change": [
            "How did revenue last month compare to the previous month?"
        ],
        "Top seller": [
            "Which sales rep contributed the most revenue this year?"
        ],
        "Last 2 years trend": [
            "Create a line graph of showing revenue for the last 2 years."
        ],
        "Top sales rep": [
            "Which sales rep contributed the least revenue this year?"
        ],
        "Avg. revenue/customer": [
            "What was the average revenue per customer last quarter?",
            "What was the average revenue per customer last year?"
        ],
        "New revenue": [
            "What was the total revenue from new customers last month? How did that compare to the previous month?",
            "What was the total revenue from new customers last year?"
        ],
        "Cumulative revenue": [
            "Create a line chart showing the cumulative revenue generated per month for the last 12 months."
        ]
    },
    "Pipeline Formation": {
        "Opportunities created": [
            "Create a chart showing the number of opportunities created per month for the last 6 months.",
            "Create a chart showing the number of deals created per month for the last 12 months."
        ],
        "Deal creation trend": [
            "Create a stacked bar chart showing the number of deals created per month per sales person for the last 6 months. The x axis should display the months and the bars should be broken by rep.",
            "Create a chart showing the number of deals created per week for the last 6 weeks",
            "Create a chart showing the number of new opportunities generated per week for the last 8 weeks"
        ],
        "Top contributor": [
            "Who created the most opportunities last month?"
        ],
        "Pipeline trend": [
            "Create a stacked area chart showing the total pipeline value per month per sales region for the last 6 months. The x-axis should display the months and the areas should be broken by region."
        ],
        "Pipeline creation": [
            "How many new deals were added to the pipeline in the last 7 days?"
        ]
    },
    "Pipeline Performance": {
        "Industry breakdown": [
            "Create a pie chart showing the distribution of deals closed by industry for the last quarter."
        ],
        "Deals lost": [
            "Create a chart showing the number of opportunities lost per month for the last 6 months."
        ],
        "Average deal per rep": [
            "Create a bar chart showing the average deal size per sales person for the last 12 months."
        ],
        "Top contributor": [
            "Who closed the highest value deal last month?",
            "Who had the highest average deal size last quarter?"
        ],
        "Deal velocity this Q": [
            "What was the average deal velocity for deals that were closed-won this quarter?"
        ],
        "Deal velocity last Q": [
            "What was the average deal velocity for deals that were closed-won last quarter?"
        ],
        "Conversion rate": [
            "Create a chart showing the conversion rate from opportunities to deals per month for the last 6 months."
        ]
    },
    "Target Achievement": {
        "Revenue target": [
            "What is our forecasted goal achievement for the year?",
            "What is our forecasted goal achievement for the quarter?",
            "What is our forecasted goal achievement for the month?"
        ]
    },
    "Recent Wins": {
        "Most recent win": [
            "What was the most recent closed-won deal?"
        ],
        "Top 10 recent wins": [
            "What were the top 10 closed-won deals of the last 30 days?"
        ],
        "Top 3 recent wins": [
            "What were the top 3 closed-won deals of the last 30 days?"
        ],
        "Deal velocity": [
            "What was the average deal velocity for the 5 most recent deals that were closed-won?"
        ]
    },
    "Churned Customers": {
        "Churns trend": [
            "Create a chart showing the number of churns per month for the last 6 months"
        ],
        "Churns summary": [
            "Create a table that lists out all of the customers that churned last month. Include the corresponding revenue amount, sales owner, industry, and launch date.",
            "Create a table that lists out all of the customers that churned last quarter."
        ],
        "Churns last year": [
            "Create a table that lists all of the customers that churned last year. Include the corresponding revenue amount, sales owner, industry, and launch date."
        ],
        "Churn impact": [
            "What is the total revenue lost due to churn in the last quarter?"
        ]
    },
    "At Risk Opportunities": {
        "Plan for deals at risk": [
            "Which deals in the pipeline are at risk of slipping, and what can we do to prevent this?"
        ],
        "Current deals at risk": [
            "How many deals are currently at risk in the pipeline?"
        ],
        "Deals at risk": [
            "Which deals in the pipeline are at risk of being lost, and what can we do to salvage them?"
        ]
    },
    "Pipeline Quality": {
        "Past due opportunities": [
            "How many deals in the pipeline are past due?"
        ],
        "Past due summary": [
            "Create a table summarizing all of the deals that are past due. Include the deal amount, deal owner, close date, and creation date."
        ],
        "Deal engagement": [
            "How many deals have not been contacted in the last 14 days?"
        ],
        "Upcoming at risk deals": [
            "How many deals in the pipeline are scheduled to close this month but have no recent activity?"
        ],
        "Upcoming wins": [
            "Create a table summarizing all of the deals that are scheduled to close next month. Include the deal amount, deal owner, expected close date, and last contact date."
        ],
        "No recent follow-up": [
            "How many deals have not had a follow-up action in the last 30 days?"
        ],
        "No activity last 3 weeks": [
            "Create a report listing all deals with no activity in the last 21 days. Include deal amount, deal owner, expected close date, and creation date."
        ],
        "Deals without activity": [
            "How many deals are currently in the proposal stage but have no scheduled follow-up actions?"
        ]
    },
    "Pipeline Activity": {
        "Top 5 deals": [
            "What were the top 5 closed-won deals of the last quarter?"
        ],
        "Avg. Deal size": [
            "What was the average deal size for the 10 most recent deals that were closed-won?"
        ],
        "New leads last 6 months": [
            "Create a chart showing the number of new leads per month for the last 6 months."
        ],
        "In negotiation": [
            "How many deals are currently in the negotiation stage?"
        ],
        "Deals without updates": [
            "Create a table summarizing all of the deals that have not been updated in the last 30 days. Include the deal amount, deal owner, close date, and last contact date."
        ]
    }
}
import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    showPassword: false,
    showPasswordVerify: false,
    passwordVerify: '',
    isChecked: false,
    status: '',
    verificationCode: '',
    signInFailed: false,
    signInLoading: false,
  },
  reducers: {
    setShowPassword: (state, action) => {
      state.showPassword = action.payload;
    },
    setShowPasswordVerify: (state, action) => {
      state.showPasswordVerify = action.payload;
    },
    setPasswordVerify: (state, action) => {
      state.passwordVerify = action.payload;
    },
    setIsChecked: (state, action) => {
      state.isChecked = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setVerificationCode: (state, action) => {
      state.verificationCode = action.payload;
    },
    setSignInFailed: (state, action) => {
      state.signInFailed = action.payload;
    },
    setSignInLoading: (state, action) => {
      state.signInLoading = action.payload;
    },
  },
});

export const {
  setShowPassword,
  setShowPasswordVerify,
  setPasswordVerify,
  setIsChecked,
  setStatus,
  setVerificationCode,
  setSignInFailed,
  setSignInLoading,
} = loginSlice.actions;
export default loginSlice.reducer;
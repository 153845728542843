import React, { useState, useEffect, useRef } from "react";
import Logo from "../../assets/LandingPageAssets/logo2.svg";
import LogoEmblem from "../../assets/LandingPageAssets/logo-emblem.svg";
import LogoEmblemMinimal from "../../assets/LandingPageAssets/logo-emblem-minimal.svg";
import HeroBgImg from "../../assets/LandingPageAssets/hero-bg.png";
import FeaturesImg1 from "../../assets/LandingPageAssets/feature-img-1.svg";
import FeaturesImg2 from "../../assets/LandingPageAssets/feature-img-2.svg";
import FeaturesImg3 from "../../assets/LandingPageAssets/feature-img-3.svg";
import ThatswhyBg from "../../assets/LandingPageAssets/thatswhy-bg.png";
import MenuIcon from "../../assets/LandingPageAssets/menu-icon.svg";
import CloseIcon from "../../assets/LandingPageAssets/close-icon.svg";
import "./style/LandingPage.css";

import gsap from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { useNavigate } from 'react-router-dom';

function isValidEmail(email) {
  // Regular expression pattern for validating email format
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return (emailPattern.test(email) && email !== "");

}

const LandingPage = () => {
  const navigate = useNavigate();
  const firstNameRef = useRef();
  const emailRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isHeaderMobVisible, setIsHeaderMobVisible] = useState(false);
  const smootherRef = useRef(null);
  gsap.registerPlugin(
    ScrollToPlugin,
    Draggable,
    ScrollSmoother,
    ScrollTrigger,
    SplitText,
  );

  useEffect(() => { 
    ScrollSmoother.create({
      wrapper: '#smooth-wrapper',
      content: '#smooth-content',
      smooth: 1.5,
      effects: true,
      smoothTouch: 0.1,
    });
    let landingAnim = gsap.timeline({
      delay: 1,
    });
    landingAnim
      .fromTo(
        "header",
        {
          opacity: 0,
        },
        {
          opacity: 1,

          duration: 1,
        }
      )
      .fromTo(
        ".hero-section h1",
        {
          opacity: 0,
          y: "1.5rem",
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        "<0.5"
      )
      .fromTo(
        ".hero-section p",
        {
          opacity: 0,
          y: "1.5rem",
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        "<0.5"
      )
      .fromTo(
        ".hero-section button",
        {
          opacity: 0,
          y: "1.5rem",
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        "<0.5"
      );

    let featuresGridAnim = gsap.timeline({
      scrollTrigger: {
        trigger: ".features-grid",
        start: "top 85%",
        end: "top 40%",
        scrub: 1,
      },
    });

    featuresGridAnim.fromTo(
      ".features-card",
      {
        scale: 0.85,
        opacity: 0,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 2,
        stagger: 0.25,
      }
    );

    const simplicityHeading = new SplitText(".simplicity-content h5", {
      type: "chars",
      charsClass: "simplicity-heading-char-++",
      reduceWhiteSpace: true,
    });
    let simplicityAnim = gsap.timeline({
      scrollTrigger: {
        trigger: ".simplicity-content h5",
        start: "top 85%",
        end: "top 40%",
        scrub: 1,
      },
    });
    simplicityAnim.fromTo(
      ".simplicity-content h5",
      {
        opacity: 0,
        y: "2.5rem",
      },
      {
        opacity: 1,
        y: 0,
        stagger: 0.15,
        duration: 1.5,
      }
    );

    let processGridAnim = gsap.timeline({
      scrollTrigger: {
        trigger: ".process-grid",
        start: "top 85%",
        end: "top 40%",
        scrub: 1,
      },
    });
    processGridAnim.fromTo(
      ".process-card",
      {
        scale: 0.85,
        opacity: 0,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 2,
        stagger: 0.25,
      }
    );

    gsap.fromTo(
      "header .header-content",
      {
        backdropFilter: "blur(0)",
      },
      {
        backdropFilter: "blur(10px)",
        delay: 1,
        duration: 1,
      }
    );
  }, []);


  // Enable scrolling
  useEffect(() => { 
    smootherRef.current = ScrollSmoother.create({
      smooth: 1.35,
      effects: true,
      smoothTouch: 1,
    });
    // Change the overflow property
    document.body.style.overflow = 'scroll';
    document.documentElement.style.overflow = 'scroll';
    // Cleanup to revert the style when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
      smootherRef.current?.kill();
    };
    
  }, []);

  useEffect(() => { 
    if (isHeaderMobVisible) {
      gsap.fromTo(".header-mob", { opacity: 0 }, { opacity: 1, duration: 0.5 });
    } else {
      gsap.to(".header-mob", { opacity: 0, duration: 0.5 });
    }
  }, [isHeaderMobVisible]);

  const toggleHeaderMob = () => setIsHeaderMobVisible((v) => !v);
  const scrollToAbout = () => {
    smootherRef.current?.scrollTo("#thats-why", true, "top 150px");
    setIsHeaderMobVisible(false);
  };
  const scrollToProduct = () => {
    smootherRef.current?.scrollTo("#solution", true, "top 150px");
    setIsHeaderMobVisible(false);
  };
  const scrollToBottom = () => {
    console.log(smootherRef, smootherRef.current)
    smootherRef.current?.scrollTo("#emailbox", true, "top -500px");
    setIsHeaderMobVisible(false);
  };


  const handleSend = (e) => {
    const email = emailRef.current.value;
    const firstName = firstNameRef.current.value;
    setIsValid(isValidEmail(email) && firstName !== "");
    if (email === "" || !isValidEmail(email)) return;
    var currentdate = new Date();
    var datetime = currentdate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    // Create a new FormData object
    const formDatab = new FormData();
    formDatab.append('Email', email);               // Append email value
    formDatab.append('FirstName', firstName);               // Append firstName value
    formDatab.append('Datetime', datetime);             // Append datetime value
    formDatab.append('LastName', "");               // Append lastName value
    formDatab.append('CompanyName', "");               // Append CompanyName value
    formDatab.append('PhoneNumber', "");               // Append PhoneNumber value

    fetch(
      "https://script.google.com/macros/s/AKfycbzEkSbS-EVUX6e_7fM7zy4daTfCeRuhe2741GmSLhvvx5dx4Az0qOtjKllZoum5X1oY/exec",
      {
        method: "POST",
        contentType: "multipart/form-data",
        body: formDatab
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
    emailRef.current.value = "";
    firstNameRef.current.value = "";
    setIsSubmitted(true);
  };

  return (
    <div id="smooth-wrapper">
      <div id="smooth-content">
        <div className="home-page">
          <img
            data-speed={1.25}
            src={HeroBgImg}
            className="hero-bg"
            alt="hero-bg"
          />
          <section className="hero-section">
            <div className="box-sm">
              <div className="hero-content">
                <h1>
                  finally, <br />
                  <span>
                    effortless <br /> insights
                  </span>
                </h1>

                <p>
                  We make revenue teams more efficient <br /> than ever, so
                  they can excel at what they do <br /> best.
                </p>

                <button onClick={scrollToBottom} className="tryourbeta">
                  TRY TODAY
                </button>
              </div>
            </div>
          </section>
          <section className="features-section">
            <img src={ThatswhyBg} alt="" />
            <div className="box-lg">
              <div className="features-content">
                <h3>Getting business insights is still a struggle</h3>
                <div className="features-grid">
                  <div className="features-card">
                    <img src={FeaturesImg1} alt="" />
                    <h4>350 Charts</h4>
                    <p>
                      Per company, spread across 10-25 biz KPI dashboards.
                    </p>
                  </div>
                  <div className="features-card">
                    <img src={FeaturesImg2} alt="" />
                    <h4>13 Applications</h4>
                    <p>Used by each employee at the average company.</p>
                  </div>
                  <div className="features-card">
                    <img src={FeaturesImg3} alt="" />
                    <h4>2.5 Hours</h4>
                    <p>Spent every day by sales leaders reviewing reports.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-sm">
              <div className="thatswhy-grid" id="thats-why">
                <div className="left">
                  <h3>
                    That's why <br /> we're...{" "}
                  </h3>
                </div>
                <div className="right">
                  <p>
                    Not another dashboard solution
                    <br />
                    <b>There’s enough of those.</b>
                  </p>
                  <p>
                    Not a clunky “everything” platform
                    <br />
                    <b>They’re part of the problem.</b>
                  </p>
                  <p>
                    Not requiring complex setups
                    <br />
                    <b>When we say “effortless”, we mean it.</b>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="simplicity-section">
            <div className="box-sm">
              <div className="simplicity-content">
                <p>
                  You know how we are <b>not</b> solving these challenges.
                </p>
                <p>
                  We studied the core problem and set out to deliver the{" "}
                  <br /> one thing no one is offering yet:
                </p>
                <h5>simplicity</h5>
              </div>
            </div>
          </section>
          <section className="process-section">
            <div className="box-sm">
              <div className="process-grid" id="solution">
                <div className="process-card">
                  <h4>Product</h4>
                  <p>
                    Our product intuitively leverages AI to help you answer
                    questions confidently and quickly.
                  </p>
                </div>
                <div className="process-card">
                  <h4>Expert services</h4>
                  <p>
                    Our revenue experts help you transform your tech stack
                    monster into a machine that works for you.
                  </p>
                </div>
                <div className="process-card solution-card">
                  <h4>Solution</h4>
                  <p>
                    Available as single modules or as an end-to-end offering,
                    an annual audit or ongoing consulting, depending on your
                    requirements.
                  </p>
                  <img src={LogoEmblem} alt="" />
                </div>
              </div>
            </div>
            <div className="box-lg">
              <div className="email-container">
                <div className="email-box" id="emailbox">
                  <h4>Discover an effortless approach to revenue insights</h4>
                  <div className="email-grid">
                    <input type="text" placeholder="full name" ref={firstNameRef} />
                    <input type="text" placeholder="work e-mail" ref={emailRef} />
                  </div>
                  <button onClick={(e) => { e.preventDefault(); handleSend(e); }}>
                    <img src={LogoEmblemMinimal} alt="" />
                    TRY TODAY
                  </button>
                  {isSubmitted ? !isValid ? (<p>Please enter a valid email (name@email.com) and name.</p>) : (<p>thanks for your interest! We’ll be in touch</p>) : !isValid ? (<p>Please enter a valid email (name@email.com) and name.</p>) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {
        isHeaderMobVisible ? (
          <div className="header-mob">
            <div className="header-links">
              <a href="/login">LOGIN</a>
              <p onClick={scrollToAbout}>ABOUT</p>
              <p onClick={scrollToProduct}>PRODUCT</p>
              <p onClick={() => navigate("/privacy")}>PRIVACY</p>
              <button onClick={scrollToBottom} className="tryourbeta">
                TRY TODAY
              </button>
            </div>
            <span
              className="close-icon"
              onClick={() => {
                setIsHeaderMobVisible(false);
              }}
            >
              <img src={CloseIcon} alt="CloseIcon" />
            </span>
          </div>
        ) : null
      }
      <header>
        <div className="box-lg">
          <div className="header-content">
            <img src={Logo} alt="hero-bg" />
            <div className="header-right">
              <a href="/login">LOGIN</a>
              <p onClick={scrollToAbout}>ABOUT</p>
              <p onClick={scrollToProduct}>PRODUCT</p>
              <p onClick={() => navigate("/privacy")}>PRIVACY</p>
              <button onClick={scrollToBottom} className="tryourbeta">
                TRY TODAY
              </button>
            </div>
            <span className="menu-icon" onClick={toggleHeaderMob}>
              <img src={MenuIcon} alt="MenuIcon" />
            </span>
          </div>
        </div>
      </header>
    </div >
  );
};

export default LandingPage;

import React, { useState, useEffect } from 'react';
import './style/loading-overlay.css'; // Assuming you have some CSS for styling
import logo from '../../assets/logo.png';

const LoadingOverlay = ({ isLoading }) => {
    const [isComplete, setIsComplete] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            setIsComplete(true);
            const timer = setTimeout(() => {
                setIsHidden(true);
            }, 400); // 0.4s delay
            return () => clearTimeout(timer);
        } else {
            setIsComplete(false);
            setIsHidden(false);
        }
    }, [isLoading]);

    return (
        <div className={`loading-overlay ${isComplete ? 'complete' : ''} ${isHidden ? 'complete-hidden' : ''}`}>
            <img src={logo} alt="Loading" edge-visual-search="none" style={{ userSelect: 'none', pointerEvents: "none" }} />
        </div>
    );
};

export default LoadingOverlay;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import logoSmall from "../../assets/logo.png";
import "./style/Login.css";

function InputCode() {
    const location = useLocation();
    const [username, setUsername] = useState(location.state);
    const [verificationCode, setVerificationCode] = useState("");
    const [status, setStatus] = useState('');
    const navigate = useNavigate();

    useEffect(() => { 
        if (username && username !== "") {
            console.log("username should be:", username);
        }
        else {
            navigate("/login");
        }
    }, []);

    return (
        <div className='login-container'>
            <div className='login'>
                <img src={logoSmall} alt="Maester" className={`logo`} />
                <form className='login-input-container'>
                    <div className='header-label'> Hi {username} <br></br> Please enter the verification code sent to your inbox to change your password.</div>
                    <input className='login-input' type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} placeholder="Code" />
                </form>
                <button className='login-button' onClick={() => {
                    if (verificationCode.length > 0) { //TODO better conditions
                        navigate("/change-password", { state: { username, verificationCode } });
                    }
                    else {
                        setStatus("failed");
                    }
                }}>Submit</button>
                <label className={`status ${status}`} >
                    {status === "failed" ? "Verification Failed" : " "}
                    <br />
                    {status === "failed" ? "Please check your code and try again." : " "}
                </label>
            </div>
        </div>
    );
}

export default InputCode;



import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    isPopupOpen: false,
    popupClass: '',
    feedback: '',
    selectedFiles: [],
    previewFile: {}
  },
  reducers: {
    setIsPopupOpen: (state, action) => {
      state.isPopupOpen = action.payload;
    },
    setPopupClass: (state, action) => {
      state.popupClass = action.payload;
    },
    setFeedback: (state, action) => {
      state.feedback = action.payload;
    },
    setPreviewFile: (state, action) => {
      state.previewFile = action.payload;
    },
    setSelectedFiles: (state, action) => {
      state.selectedFiles = action.payload;
    },
  },
});

export const { setIsPopupOpen, setPopupClass, setFeedback, setSelectedFiles, setPreviewFile } = popupSlice.actions;
export default popupSlice.reducer;
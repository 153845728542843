export const handleFeedback = async ({ feedback, thumbsUp = null, selectedFiles = null, chatHistory = null, submitClicked = true }) => {
    const currentdate = new Date();
    const formData = new FormData();
    const datetime = currentdate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    formData.append('Datetime', datetime);
    formData.append('Feedback', submitClicked ? feedback : "");
    const chatHistoryString = chatHistory?.map(chat => `${chat.role}: ${chat.content}`).join('\n');
    chatHistoryString && formData.append('ChatHistory', chatHistoryString);
    const idealFileNamesString = selectedFiles?.map(filename => `${filename}`).join('\n');
    idealFileNamesString && formData.append('UserSelectedFiles', submitClicked ? idealFileNamesString : "");
    thumbsUp !== null && formData.append('ThumbUp', thumbsUp);

    for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
    }

    try {
        const response = await fetch("https://script.google.com/macros/s/AKfycbwyvCnmV6vNqv6RkgqCPoANtjpSwDft9Ju7e9ZqN6jg3-3e-U0x1o7mZ85qLyztYU8_-Q/exec", {
            method: "POST",
            contentType: "multipart/form-data",
            body: formData
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseBody = await response.json();
        console.log("Feedback sent!", responseBody);
        return responseBody;
    } catch (error) {
        console.error('Failed to send feedback:', error);
        throw error;
    }
};
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/popup.css';
import { closePopup } from '../Popup';
import Button from '../../Button/Button';
import { toggleSnackbar } from '../../Snackbar/Snackbar';
import { setGoogleData, setGoogleFileNames } from '../../../state/slices/googleSlice';
import { addSheet } from '../../../api/Google/ManageSheets';
import { getFileNames } from '../../../api/Files';

export function GooglePopup() {
    const dispatch = useDispatch();
    const { isPopupOpen } = useSelector(state => state.popup);
    const { googleData } = useSelector(state => state.google);
    const [isDownloading, setIsDownloading] = useState(false);

    function toggleTabSelection(tabName) {
        const { selectedTabNames } = googleData;
        dispatch(setGoogleData({
            ...googleData,
            selectedTabNames: selectedTabNames.includes(tabName)
                ? selectedTabNames.filter(file => file !== tabName)
                : [...selectedTabNames, tabName]
        }));
    }

    function renderGoogleFiles() {
        const googleFiles =
            googleData?.tabNames.length > 0 && googleData.tabNames.map((tabName, index) => {
                const displayName = tabName.replace("googlesheets_", "").replace("_", " ").replace(".csv", "");
                return (
                    <Button
                        google
                        active={googleData.selectedTabNames.includes(tabName)}
                        key={"file" + index}
                        type={'medium'}
                        label={displayName}
                        tooltip={displayName}
                        className={`button medium`}
                        onClick={() => toggleTabSelection(tabName)}
                    />
                );
            });
        return googleFiles;
    };

    async function uploadTabs() {
        if (googleData.selectedTabNames && googleData.selectedTabNames.length > 0) {
            toggleSnackbar(dispatch, "info", "Connecting tabs...");
            setIsDownloading(true);
            const successfulUpload = await addSheet(googleData);
            closePopup(dispatch);
            if (successfulUpload) {
                toggleSnackbar(dispatch, "complete", "Tabs connected, processing data");
                const newFiles = await getFileNames();
                dispatch(setGoogleFileNames(newFiles[1]));
                toggleSnackbar(dispatch, "complete", "Data processed successfully");
            } else {
                toggleSnackbar(dispatch, "error", "Failed to connect tabs. Please try again later.");
            }
            setIsDownloading(false);
            dispatch(setGoogleData({ data: {}, tabNames: [], selectedTabNames: [] }));
        }
    }

    return (
        <div className={`popup google ${!isPopupOpen && "hidden"}`} onClick={(e) => e.stopPropagation()}>
            <div className="header">
                <div className="title"> Connect Tabs </div>
            </div>
            <div className="content">
                <div className="title"> Which tabs would you like to connect? </div>
                <div className="container">
                    {renderGoogleFiles()}
                </div>
            </div>
            <div className="popup-footer">
                <Button type="action" disabled={isDownloading} label={"Cancel"} onClick={() => { closePopup(dispatch); }} />
                <Button type="action" disabled={isDownloading} label={"Connect"} onClick={async () => { await uploadTabs(); }} />
            </div>
        </div>
    );

}
import { createSlice } from '@reduxjs/toolkit';

const trendingTopicsSlice = createSlice({
  name: 'trendingTopics',
  initialState: {
    selectedTT: '',
    prompts: [],
    sortedTopics: [],
    sortedPrompts: [],
  },
  reducers: {
    setSelectedTT: (state, action) => {
      state.selectedTT = action.payload;
    },
    setPrompts: (state, action) => {
      state.prompts = action.payload;
    },
    setSortedTopics: (state, action) => {
      state.sortedTopics = action.payload;
    },
    setSortedPrompts: (state, action) => {
      state.sortedPrompts = action.payload;
    },
  },
});

export const { setSelectedTT, setPrompts, setSortedTopics, setSortedPrompts } = trendingTopicsSlice.actions;
export default trendingTopicsSlice.reducer;
import { post } from 'aws-amplify/api';
import { getJwtToken } from "../../functions/useJwtToken"

export const checkAccessToken = async () => {
  const jwtToken = await getJwtToken();
  if (!jwtToken) {
    console.error("JWT Token Fetch Error")
    return null
  }
  // Step 1: Check if the user has an access token in the database (requests to the backend API at GoogleAuthCheck index.py)
  try {
    const responseBody = await (await post({
      apiName: 'MaesterV3API',
      path: '/api/v3/google/auth-check',
      options: {
        headers: { Authorization: jwtToken },
        body: {}
      }
    }).response).body.json();
    if (responseBody.access_token) {
      // Step 2b: User has access token
      // console.log("checkAccessToken: Access token found,", response.access_token);
      return responseBody.access_token;
    } else {
      // Step 2: If the user does not have an access token, initiate the OAuth flow
      // console.log("checkAccessToken: No access token found,", response.access_token);
      return null;
    }
  } catch (error) {
    console.error("checkAccessToken: Session Check Failed", error);
    return null;
  }
};
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import mammoth from 'mammoth';
import './style/assistant-file-viewer.css';
import Button from '../../../Button/Button';
import msWordLogo from '../../../../assets/icons/msWord.png';
import { getAssistantFile } from '../../../../api/Files';
import { openPopup } from '../../../Popup/Popup';
import { setPreviewFile } from '../../../../state/slices/popupSlice';

const AssistantFile = ({ message_id, file, index }) => {
    const dispatch = useDispatch();
    const [fileData, setFileData] = useState(null);
    const { currentThreadID } = useSelector((state) => state.threads);

    useEffect(() => {
        const fetchFileData = async () => {
            const { filename, mime_type, encoding } = file;
            const data = await getAssistantFile({
                thread_id: currentThreadID,
                message_id: message_id,
                filename: filename,
            });

            setFileData({ ...data, mime_type, encoding });
        };
        fetchFileData();
    }, [message_id, file]);

    // Helper function to render previews for different file types
    const renderPreview = (fileData) => {
        const { filename, mime_type, encoding, content } = fileData;
        if (mime_type.includes('image')) {
            // Render image files
            return (
                <img src={`data:${mime_type};${encoding},${content}`} className="file-preview image" alt={filename} />
            );
        } else if (mime_type === 'text/csv') {
            // Render CSV files
            const decodedContent = encoding === 'base64' ? atob(content) : content;
            const lines = decodedContent ? decodedContent.split('\n').filter((line, idx, arr) => idx !== arr.length - 1 || line.trim() !== '').slice(0, 7) : [""];

            return (
                <table className='file-preview csv'>
                    <tbody>
                        {lines.map((line, idx) => (
                            <tr key={idx}>
                                {line.split(',').map((cell, cellIdx) => (
                                    <td key={cellIdx} style={{ border: '1px solid #ddd', padding: '8px' }}>
                                        {cell}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (mime_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // Render DOCX files
            return (
                <div className="file-preview">
                    <img src={msWordLogo} alt="DOCX file" className="docx" />
                </div>
            );
            
        } else if (mime_type.startsWith('text/') || mime_type === 'application/json') {
    // Render text or JSON files
    const decodedContent = encoding === 'base64' ? atob(content) : content;
    const limitedContent = decodedContent ? decodedContent.slice(0, 280) : "";
    return <pre className="file-preview text">{limitedContent}</pre>;
} else if (mime_type === 'application/pdf') {
    // Render PDF files
    return (
        <iframe
            src={`data:${mime_type};${encoding},${content}`}
            title={filename}
            width="100%"
            height="500px"
            style={{ border: 'none' }}
        />
    );
} else {
    // Unsupported file type
    return <p className='file-preview text'>Preview not yet available for this file type: {mime_type}</p>;
}
    };

if (!fileData)
    return (
        <div key={index} className='file-container'>
            <div className="preview-container" style={{ alignItems: "center" }}>
                Loading...
            </div>
            <div className='file-bar' />
        </div>
    );

const { filename, mime_type, encoding, content } = fileData;
return (
    <div key={index} className="file-container" onClick={() => { dispatch(setPreviewFile(fileData)); openPopup(dispatch, "preview-file"); }}>
        <div className="preview-container">
            {renderPreview(fileData)}
        </div>
        <div className='file-bar'>
            <div style={{ display: "flex", height: "100%", flexDirection: "row", alignContent: "center" }}>
                <Button type="icon-medium" asLabel iconStyle={{ margin: "0" }} startIcon="description" />
                <div className='file-label'>
                    {filename}
                </div>
            </div>
            <a
                href={`data:${mime_type};${encoding},${content}`}
                download={filename}
                style={{ margin: "auto 0" }}
                onClick={(e) => e.stopPropagation()}
            >
                <Button type="icon-medium" startIcon="file_download" />
            </a>
        </div>
    </div>
);
};

export const RenderFullFile = (fileData) => {
    const [isZoomed, setisZoomed] = useState(false);
    const { filename, mime_type, encoding, content } = fileData;

    if (mime_type.includes('image')) {
        // Render image files
        return (
            <img
                src={`data:${mime_type};${encoding},${content}`}
                className={`file-content image ${isZoomed ? "zoomed" : ""}`}
                alt={filename}
                onClick={() => setisZoomed(isZoomed => !isZoomed)}
            />
        );
    } else if (mime_type === 'text/csv') {
        // Render CSV files
        const decodedContent = encoding === 'base64' ? atob(content) : content;
        const lines = decodedContent ? decodedContent.split('\n').filter((line, idx, arr) => idx !== arr.length - 1 || line.trim() !== '') : [""];

        return (
            <table className={`file-content csv`}>
                <tbody>
                    {lines.map((line, idx) => (
                        <tr key={idx}>
                            {line.split(',').map((cell, cellIdx) => (
                                <td key={cellIdx} style={{ border: '1px solid #ddd', padding: '8px' }}>
                                    {cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    } else if (mime_type.startsWith('text/') || mime_type === 'application/json') {
        // Render text or JSON files
        const decodedContent = encoding === 'base64' ? atob(content) : content;
        return <pre className={`file-content text`}> {decodedContent}</pre>;
    } else if (mime_type === 'application/pdf') {
        // Render PDF files
        return (
            <iframe
                src={`data:${mime_type};${encoding},${content}`}
                title={filename}
                width="100%"
                height="500px"
                style={{ border: 'none' }}
            />
        );
    } 
    
    else if (mime_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return <h1 className='file-content text'>Preview not available for Word Documents </h1>;
    }
    
    else {
        // Unsupported file type
        return <h1 className='file-content text'>Preview not available for this file type: {mime_type}</h1>;
    }
};

export const renderFiles = (message_id, output_files) => {
    if (!output_files || output_files.length === 0) return null;
    return (
        <div className="files-list">
            {output_files.map((file, index) => (
                <AssistantFile key={index} message_id={message_id} file={file} index={index} />
            ))}
        </div>
    );
};

export default AssistantFile;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../style/detail.css';
import '../style/intake.css';
import { setDetailClass } from '../../../state/slices/sidemenuSlice.jsx';
import { setGeneralContext, setColors } from '../../../state/slices/dataIntakeSlice.jsx';
import { DetailFooter } from '../Detail.jsx';
import Button from '../../Button/Button.jsx';
import { setContext, setPreferences } from '../../../api/Explanation.jsx';
import { toggleSnackbar } from '../../Snackbar/Snackbar.jsx';

export function IntakeDetail() {
    const dispatch = useDispatch();
    const { detailClass } = useSelector(state => state.sidemenu);
    const { chartFormatting, keywords, colors, generalContext = '' } = useSelector(state => state.dataIntake);
    const { user, theme, audio } = useSelector(state => state.utilities);
    const [localGeneralContext, setLocalGeneralContext] = useState(generalContext);


    useEffect(() => {
        if (generalContext) {
            setLocalGeneralContext(generalContext);
        }
    }, [generalContext]);

    const handleSubmit = async () => {
        if (generalContext !== localGeneralContext) {
            toggleSnackbar(dispatch, "info", "Updating company information...");
            try {
                let contextResponse;
                contextResponse = await setContext("Organization Level Context", localGeneralContext);
                if (contextResponse) {
                    toggleSnackbar(dispatch, "complete", "Company information updated!");
                    dispatch(setDetailClass("settings"));
                } else {
                    toggleSnackbar(dispatch, "error", "Failed to update company information");
                }
            } catch (error) {
                toggleSnackbar(dispatch, "error", "Failed to update company information");
            }
        } else {
            dispatch(setDetailClass("settings"));
        }
    };

    return (
        <div className={`detail ${detailClass === "intake" ? detailClass : 'hidden'}`}>
            <div className="content">
                <h1>Organization Information for <span className='primary'> {user.organization} </span> </h1>
                <br />
                <h1>Company General Context </h1>
                <textarea
                    className="general-context"
                    type="text"
                    placeholder="Add anything you'd like Maester to understand about your organization."
                    value={localGeneralContext || ""}
                    onChange={(e) => {
                        setLocalGeneralContext(e.target.value);
                    }}
                />
            </div>
            <div className="submit-buttons-container">
                <Button
                    type="action"
                    label="Cancel"
                    onClick={() => {
                        setLocalGeneralContext(generalContext);
                        dispatch(setDetailClass("settings"));
                    }}
                />
                <Button
                    type="action"
                    label="Submit"
                    onClick={handleSubmit}
                />
            </div>
            {DetailFooter(dispatch)}
        </div>
    );
};
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/popup.css';
import { closePopup } from '../Popup';
import { toggleSnackbar } from '../../Snackbar/Snackbar';
import Button from '../../Button/Button';
import { handleFeedback } from "../../../api/Feedback";
import { setFeedback } from '../../../state/slices/popupSlice';

export function FeedbackPopup() {
    const dispatch = useDispatch();
    const { isPopupOpen, popupClass, feedback } = useSelector(state => state.popup);


    const submitFeedback = () => {
        if (feedback !== "") {
            toggleSnackbar(dispatch, "info", "Sending feedback...");
            handleFeedback({
                feedback: feedback,
            })
                .then(response => {
                    if (response) {
                        toggleSnackbar(dispatch, "complete", "Feedback sent successfully!");
                    } else {
                        toggleSnackbar(dispatch, "error", "Feedback failed to send. Please try again later.");
                    }
                })
                .catch(error => {
                    console.error('Failed to send feedback:', error);
                    toggleSnackbar(dispatch, "error", "Feedback failed to send. Please try again later.");
                });
            closePopup(dispatch);
        } else {
            toggleSnackbar(dispatch, "error", "Please provide feedback before submitting.");
        };
    };

    return (
        <div className={`popup ${popupClass} ${isPopupOpen ? "" : "hidden"}`} onClick={(e) => e.stopPropagation()}>
            <div className="header">
                <div className="title"> Thank you for your feedback! </div>
            </div>
            <div className="content">
                <textarea className="text-input" placeholder='Use this space to share your thoughts.' value={feedback} onChange={(e) => { e.preventDefault(); dispatch(setFeedback(e.target.value)); }}></textarea>
            </div>
            <div className="popup-footer">
                <Button type="action" label={"Cancel"} onClick={() => { closePopup(dispatch); }} />
                <Button type="action" label={"Submit"} onClick={() => { submitFeedback(); }} />
            </div>
        </div>
    );
}
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/detail.css';
import { setDetailClass } from '../../../state/slices/sidemenuSlice';
import signOutFn from "../../../functions/SignOut.jsx"
import { openPopup } from '../../Popup/Popup';
import { DetailFooter } from '../Detail.jsx';
import Button from '../../Button/Button';
import { toggleTheme, toggleAudio } from '../../../state/slices/utilitiesSlice';
import ModelSelector from '../../ModelSelector/ModelSelector.jsx';
import { useNavigate } from 'react-router-dom';
import { setPreferences } from '../../../api/Explanation.jsx';

export function SettingsDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { detailClass } = useSelector(state => state.sidemenu);
    const { theme, audio } = useSelector(state => state.utilities);
    const { chartFormatting, keywords, colors } = useSelector(state => state.dataIntake);
    const { user } = useSelector(state => state.utilities);
    return (
        <div className={`detail small ${detailClass === "settings" ? detailClass : 'hidden'}`}>
            <div className='content'>
                <h1>Preferences</h1>
                <div>
                    <Button type="medium" startIcon={theme === "light" ? "light_mode" : "dark_mode"} onClick={() => { setPreferences({ chart_formatting: chartFormatting, keywords, colors, theme: theme === "dark" ? "light" : "dark", audio }); dispatch(toggleTheme()); }} label={`Current Theme: ${theme === "light" ? "Light" : "Dark"}`} tooltip="Click to Toggle" />
                    <Button type="medium" startIcon={audio ? "notifications_active" : "notifications_off"} onClick={() => { setPreferences({ chart_formatting: chartFormatting, keywords, colors, theme, audio: !audio }); dispatch(toggleAudio()); }} label={`Audio: ${audio ? "On" : "Off"}`} tooltip="Click to Toggle" />
                </div>
                <h1>Company Info</h1>
                <div>
                    <Button type="medium" startIcon={"business"} onClick={() => dispatch(setDetailClass("intake"))} label={`Company Intake`} />
                    <Button type="medium" startIcon={"bar_chart"} onClick={() => dispatch(setDetailClass("formatting"))} label={`Chart Formatting`} />
                    <Button type="medium" startIcon={"book"} onClick={() => dispatch(setDetailClass("keywords"))} label={`Keywords`} />
                </div>
                <h1>More</h1>
                <div>
                    <Button type="medium" startIcon={"thumb_up"} onClick={() => openPopup(dispatch, "feedback")} label={`Leave Feedback`} />
                    <Button type="medium" startIcon={"logout"} onClick={() => signOutFn(dispatch, navigate)} label={`Log Out`} />
                    <ModelSelector />
                </div>
                {user.role && <h3 style={{ width: '100%', marginTop: "1rem", textAlign: "center", userSelect: "none", color: "var(--primary-color)" }}> Account Type: {user.role.charAt(0).toUpperCase() + user.role.slice(1)} </h3>}
            </div>
            {DetailFooter(dispatch)}
        </div>
    );
};


import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style/message-input.css';
import Button from '../Button/Button';
import { addMessage, editMessage, setMessageTree, setMessages, setPresetMessage, toggleNotify, findUserMessageByRunId } from '../../state/slices/messagesSlice';
import { setCurrentThreadID, setThreads, setCurrentThreadArchived } from '../../state/slices/threadsSlice';
import { setIsSendDisabled } from '../../state/slices/utilitiesSlice';
import { setSendWithContextFiles } from "../../state/slices/contextSlice";
import { openPopup } from '../Popup/Popup';
import { sendMessage, getResponse, getAllThreads } from '../../api/Ask';
import messageSuccess from '../../assets/sounds/audioSuccess.wav';
import messageError from '../../assets/sounds/audioFailure.wav';


export const newChat = (dispatch) => {
    dispatch(setSendWithContextFiles([]));
    dispatch(setCurrentThreadArchived(false));
    dispatch(setIsSendDisabled(false));
    dispatch(setCurrentThreadID(null));
    dispatch(setMessages([]));
    dispatch(setMessageTree([]));
    window.history.pushState(null, null, "/chat");
}

const MessageInput = () => {
    const dispatch = useDispatch();
    const { messages, presetMessage, notify } = useSelector(state => state.messages);
    const { currentThreadID, threads } = useSelector(state => state.threads);
    const { selectedModels, temperature } = useSelector(state => state.modelSelector);
    const [inputMessage, setInputMessage] = useState('');
    const [veryBeginning, setVeryBeginning] = useState(0);
    const { isSendDisabled, audio } = useSelector(state => state.utilities);
    const { sendWithContextFiles } = useSelector(state => state.context);
    const textareaRef = useRef(null);

    //Auto focus bar on page load
    useEffect(() => {
        const originalPushState = window.history.pushState;
        window.history.pushState = function () {
            originalPushState.apply(this, arguments);
            if (arguments[2] === "/chat" && textareaRef.current) {
                textareaRef.current.focus();
            }
        };

        // Initial focus
        if (textareaRef.current) {
            textareaRef.current.focus();
        }

        // Cleanup
        return () => {
            window.history.pushState = originalPushState;
        };
    }, []);

    //Resize textarea up to 5 lines
    useEffect(() => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            const scrollPos = window.scrollY;
            textarea.style.height = 'auto';
            const maxHeight = 1.5 * 16 * 5;
            const newHeight = Math.min(textarea.scrollHeight, maxHeight);
            textarea.style.height = `${newHeight}px`;
            window.scrollTo(0, scrollPos);
        }
        if (presetMessage !== "") {
            setInputMessage(presetMessage);
            if (textareaRef.current) {
                textareaRef.current.focus();
            }
            dispatch(setPresetMessage(""));
            dispatch(toggleNotify());
            setTimeout(() => {
                dispatch(toggleNotify());
            }, 500);
        }
    }, [inputMessage, presetMessage]);


    const handleSend = async () => {
        setVeryBeginning(Date.now());
        if (inputMessage.trim() === "") return;
        dispatch(setIsSendDisabled(true));
        const timestamp = Date.now().toString();
        dispatch(addMessage({ content: inputMessage, role: "user", created_at: timestamp, message_id: "user-temp", type: "text" }));

        setInputMessage('');

        const modelsToSend = selectedModels.length === 0
            ? process.env.REACT_APP_POSSIBLE_MODELS.split(',').slice(0, 4)
            : selectedModels;

        const parent_message_id = messages.length > 0
            ? messages.slice().reverse().find(msg => !['user-temp', 'assistant-loading-id', 'assistant-error'].includes(msg.message_id))?.message_id
            : null;

        const askResponsePromise = sendMessage({
            currentThreadID,
            message: inputMessage,
            selectedModels: modelsToSend,
            temperature,
            sendWithContextFiles,
            parent_message_id
        });

        await new Promise(r => setTimeout(r, 750)); // sleep 0.75 second, because it feels jarring to have the loading message appear so quickly.

        dispatch(addMessage({ content: "Starting Maester AI processing algorithm", role: 'assistant', timestamp: timestamp, message_id: "assistant-loading-id", type: "text" }));
        const timeoutPromise = new Promise(resolve => setTimeout(() => resolve({ timedOut: true }), 5000)); // 5 second timeout
        const result = await Promise.race([
            askResponsePromise,
            timeoutPromise
        ]);

        // If the request times out or breaks, show an error message
        if (!result) {
            dispatch(addMessage({ content: "Sorry, I'm having trouble sending your request. Please try again.", role: 'assistant', type: "text", message_id: "assistant-error" }));
            dispatch(setIsSendDisabled(false));
            return;
        }

        if (!result.data_sources_being_accessed) {
            dispatch(editMessage({ message_id: "assistant-loading-id", content: "Searching relevant historical queries to improve speed and accuracy" }));
        }

        const startTime = Date.now();
        const askResponse = await askResponsePromise;

        const { thread_id, run_id, user_message_id } = askResponse;
        dispatch(setCurrentThreadID(thread_id));
        const elapsedTime = Date.now() - startTime;
        console.log("Elapsed time:", elapsedTime, "ms");
        if (askResponse.data_sources_being_accessed && askResponse.data_sources_being_accessed[0] !== "None" && askResponse.data_sources_being_accessed?.length > 0) {
            dispatch(editMessage({ message_id: "assistant-loading-id", content: "Analyzing: " + askResponse.data_sources_being_accessed }));

        }

        let messageResponseBody;
        const MAX_ATTEMPTS = 60;
        const MIN_WAIT = 3000;  // 3 seconds
        let attempts = 0;
        try {
            let prevStepDescription = null;
            while (attempts < MAX_ATTEMPTS) {
                attempts++;
                const startTime = Date.now();
                try {
                    messageResponseBody = await getResponse({
                        thread_id: askResponse.thread_id,
                        run_id: askResponse.run_id,
                        models: modelsToSend,
                        parent_message_id: user_message_id
                    });
                } catch (error) {
                    console.error("error getting message response body", error)
                    console.log("Trying one more time...")
                    messageResponseBody = await getResponse({
                        thread_id: askResponse.thread_id,
                        run_id: askResponse.run_id,
                        models: modelsToSend,
                        parent_message_id: user_message_id
                    });
                }
                const elapsedTime = Date.now() - startTime;
                // console.log(messageResponseBody);

                // Check for step description update
                if (messageResponseBody.step_description) {
                    if (prevStepDescription !== messageResponseBody.step_description) {
                        dispatch(editMessage({ message_id: "assistant-loading-id", content: messageResponseBody.step_description }));
                    }
                }

                // Check for final message
                // on dev, we want to get all messages back. otherwise, just the fastest
                //uncomment this statement and add the else back below when testing is done for dev
                // else if (process.env.REACT_APP_ENV !== "dev" || (process.env.REACT_APP_ENV === "dev" && messageResponseBody.status === "completed")) 
                else {
                    //for the dev version, this would be "intermediate response", but for the production version, this would be "first message recieved"
                    const userMessage = findUserMessageByRunId(messageResponseBody.messages, askResponse.run_id);
                    if (userMessage && userMessage.children.some(msg => msg.metadata?.is_selected_assistant)) {
                        dispatch(setMessageTree(messageResponseBody.messages));
                        if (audio) { // Assuming audioOn is true, adjust as needed
                            const sound = new Audio(messageSuccess)
                            sound.volume = 0.35;
                            sound.src = attempts >= MAX_ATTEMPTS ? messageError : messageSuccess;
                            sound.play().catch(e => console.error('Error playing audio:', e));
                        }
                        console.log("Total Time handleSend:", Date.now() - veryBeginning, "ms")
                        break;
                    } else {
                        console.log("intermediate response", messageResponseBody);
                    }
                }

                await new Promise(r => setTimeout(r, Math.max(0, MIN_WAIT - elapsedTime)));
            }

        } catch (error) {
            console.log("error sending message", error)
            dispatch(addMessage({ content: "Sorry, I'm having trouble processing your request. Please try again.", role: 'assistant', type: "text", message_id: "assistant-error" }));
            if (audio) {
                const sound = new Audio(messageError)
                sound.volume = 0.35;
                sound.src = messageError;
                sound.play().catch(e => console.error('Error playing audio:', e));
            }
        }

        dispatch(setIsSendDisabled(false));

        if (!threads.some(thread => thread.thread_id === thread_id)) {
            const allThreads = await getAllThreads();
            dispatch(setThreads(allThreads));
        }
    };

    return (
        <form className="message-input" onSubmit={(e) => { e.preventDefault(); }}>
            <textarea
                ref={textareaRef}
                disabled={isSendDisabled ? true : false}
                type="text"
                className={notify ? "notify" : ""}
                placeholder={isSendDisabled ? "Please Wait..." : "Type Your Message"}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey && !isSendDisabled) {
                        e.preventDefault();
                        handleSend();
                    }
                }}
            />
            <Button type="icon" startIcon="arrow_upward" disabled={isSendDisabled} onClick={() => handleSend()} buttonStyle={{ marginLeft: '2rem' }} />
            <Button type="icon"
                startIcon="post_add"
                active={sendWithContextFiles.length > 0}
                disabled={isSendDisabled}
                buttonStyle={{ marginLeft: '1rem' }}
                onClick={() => openPopup(dispatch, "context")} />
        </form>);
};

export default MessageInput;
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/detail.css';
import { setDetailClass } from '../../../state/slices/sidemenuSlice.jsx';
import Chip from '../../Chip/Chip.jsx';
import Button from '../../Button/Button.jsx';
import { setPreferences } from '../../../api/Explanation.jsx';
import { toggleSnackbar } from '../../Snackbar/Snackbar.jsx';
import { setChartFormatting, setColors } from '../../../state/slices/dataIntakeSlice.jsx';
import { DetailFooter } from '../Detail.jsx';

export function FormattingDetail() {
    const dispatch = useDispatch();
    const { detailClass } = useSelector(state => state.sidemenu);
    const { chartFormatting, keywords, colors = [] } = useSelector(state => state.dataIntake);
    const { audio, theme } = useSelector(state => state.utilities);
    const [isLoading] = useState(false);
    const [localChartFormatting, setLocalChartFormatting] = useState(chartFormatting);
    const [localColors, setLocalColors] = useState(colors.length ? colors : ["#F75D2C", "#BE58EA", "#EC8AB8"]);
    const isValidHex = (hex) => { return /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(hex) };

    useEffect(() => {
        if (colors) {
            setLocalColors(colors.length ? colors : ["#F75D2C", "#BE58EA", "#EC8AB8"]);
        }
    }, [colors]);

    useEffect(() => {
        if (chartFormatting) {
            setLocalChartFormatting(chartFormatting);
        }
    }, [chartFormatting]);


    const handleSubmit = async () => {
        const colorUpdate = localColors[0] !== colors[0] || localColors[1] !== colors[1] || localColors[2] !== colors[2] ? localColors : colors;
        const formattingUpdate = localChartFormatting !== chartFormatting ? localChartFormatting : chartFormatting;
        dispatch(setColors(colorUpdate));
        dispatch(setChartFormatting(formattingUpdate));
        toggleSnackbar(dispatch, "info", "Updating chart formatting preferences...");
        try {
            const response = await setPreferences({ colors: colorUpdate, chart_formatting: formattingUpdate, keywords, theme, audio });
            if (response) {
                toggleSnackbar(dispatch, "complete", "Company information updated!");
                dispatch(setDetailClass("settings"));
            } else {
                toggleSnackbar(dispatch, "error", "Failed to update company information");
            }
        } catch (error) {
            toggleSnackbar(dispatch, "error", "Failed to update company information");
        }
    };

    const toggleLocalChartFormatting = (category, property) => {
        if (localChartFormatting[category] && localChartFormatting[category][property] !== undefined) {
            setLocalChartFormatting({
                ...localChartFormatting,
                [category]: {
                    ...localChartFormatting[category],
                    [property]: !localChartFormatting[category][property]
                }
            });
        }
        else {
            console.error("check the following category and property: ", category, property)
        }
    };

    return (
        <div className={`detail small ${detailClass === "formatting" ? detailClass : 'hidden'}`}>
            <div className="content">
                <h1>Chart Formatting Preferences</h1>
                <h3> Indicate your preferences for chart formatting here. Maester will try to incorporate your selections in all charts it produces </h3>
                <br />
                <br />
                <div style={{ overflowY: "scroll" }}>
                    <h1>Color Palette </h1>
                    <div style={{ display: "flex", flexDirection: 'row', gap: '1.5rem', width: 'calc(100% - 2rem)' }}>
                        <input type="color" className='color-selector' value={localColors[0]} onChange={(e) => { setLocalColors([e.target.value, localColors[1], localColors[2]]) }} />
                        <input
                            type="text"
                            className='color-text'
                            placeholder="Primary Hex Code"
                            value={localColors[0]}
                            onChange={(e) => {
                                setLocalColors([e.target.value, localColors[1], localColors[2]])
                            }}
                            onBlur={(e) => {
                                if (!isValidHex(e.target.value)) {
                                    setLocalColors(['#000000', localColors[1], localColors[2]]);
                                }
                            }}
                            maxLength={7}
                        />
                    </div>
                    <br />
                    <div style={{ display: "flex", flexDirection: 'row', gap: '1.5rem', width: 'calc(100% - 2rem)' }}>
                        <input type="color" className='color-selector' value={localColors[1]} onChange={(e) => { setLocalColors([localColors[0], e.target.value, localColors[2]]) }} />
                        <input
                            type="text"
                            className='color-text'
                            placeholder="Secondary Hex Code"
                            value={localColors[1]}
                            onChange={(e) => {
                                setLocalColors([localColors[0], e.target.value, localColors[2]])
                            }}
                            onBlur={(e) => {
                                if (!isValidHex(e.target.value)) {
                                    setLocalColors([localColors[0], '#000000', localColors[2]]);
                                }
                            }}
                            maxLength={7}
                        />
                    </div>
                    <br />
                    <div style={{ display: "flex", flexDirection: 'row', gap: '1.5rem', width: 'calc(100% - 2rem)' }}>
                        <input type="color" className='color-selector' value={localColors[2]} onChange={(e) => { setLocalColors([localColors[0], localColors[1], e.target.value]) }} />
                        <input
                            type="text"
                            className='color-text'
                            placeholder="Tertiary Hex Code"
                            value={localColors[2]}
                            onChange={(e) => {
                                setLocalColors([localColors[0], localColors[1], e.target.value])
                            }}
                            onBlur={(e) => {
                                if (!isValidHex(e.target.value)) {
                                    setLocalColors([localColors[0], localColors[1], '#000000']);
                                }
                            }}
                            maxLength={7}
                        />
                    </div>
                    <br />
                    <h1> Title </h1>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                        <Chip
                            type={"short"}
                            startIcon="format_bold"
                            label="Bold"
                            labelStyle={{ fontWeight: "bold" }}
                            active={localChartFormatting.title.bold}
                            onClick={() => toggleLocalChartFormatting("title", "bold")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="format_underline"
                            label="Underline"
                            labelStyle={{ textDecoration: "underline" }}
                            active={localChartFormatting.title.underline}
                            onClick={() => toggleLocalChartFormatting("title", "underline")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="format_italic"
                            label="Italicize"
                            labelStyle={{ fontStyle: "italic" }}
                            active={localChartFormatting.title.italicize}
                            onClick={() => toggleLocalChartFormatting("title", "italicize")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="palette"
                            iconStyle={{ color: "var(--primary-color)" }}
                            label="Colors"
                            tooltip="Include chosen colors on charts"
                            active={localChartFormatting.title.colors}
                            onClick={() => toggleLocalChartFormatting("title", "colors")}
                        />
                    </div>
                    <h1> X Axis Label </h1>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                        <Chip
                            type={"short"}
                            startIcon="format_bold"
                            label="Bold"
                            labelStyle={{ fontWeight: "bold" }}
                            active={localChartFormatting.x_axis.bold}
                            onClick={() => toggleLocalChartFormatting("x_axis", "bold")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="format_underline"
                            label="Underline"
                            labelStyle={{ textDecoration: "underline" }}
                            active={localChartFormatting.x_axis.underline}
                            onClick={() => toggleLocalChartFormatting("x_axis", "underline")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="format_italic"
                            label="Italicize"
                            labelStyle={{ fontStyle: "italic" }}
                            active={localChartFormatting.x_axis.italicize}
                            onClick={() => toggleLocalChartFormatting("x_axis", "italicize")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="palette"
                            iconStyle={{ color: "var(--primary-color)" }}
                            label="Colors"
                            tooltip="Include chosen colors on charts"
                            active={localChartFormatting.x_axis.colors}
                            onClick={() => toggleLocalChartFormatting("x_axis", "colors")}
                        />
                    </div>
                    <h1> Y Axis Label </h1>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                        <Chip
                            type={"short"}
                            startIcon="format_bold"
                            label="Bold"
                            labelStyle={{ fontWeight: "bold" }}
                            active={localChartFormatting.y_axis.bold}
                            onClick={() => toggleLocalChartFormatting("y_axis", "bold")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="format_underline"
                            label="Underline"
                            labelStyle={{ textDecoration: "underline" }}
                            active={localChartFormatting.y_axis.underline}
                            onClick={() => toggleLocalChartFormatting("y_axis", "underline")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="format_italic"
                            label="Italicize"
                            labelStyle={{ fontStyle: "italic" }}
                            active={localChartFormatting.y_axis.italicize}
                            onClick={() => toggleLocalChartFormatting("y_axis", "italicize")}
                        />
                        <Chip
                            type={"short"}
                            startIcon="palette"
                            iconStyle={{ animation: "rotate 2s linear infinite", color: "var(--primary-color)" }}
                            label="Colors"
                            tooltip="Include chosen colors on charts"
                            active={localChartFormatting.y_axis.colors}
                            onClick={() => toggleLocalChartFormatting("y_axis", "colors")}
                        />
                    </div>
                    <h1> Gridlines on Charts </h1>
                    <Chip
                        type={"short"}
                        startIcon={localChartFormatting.gridlines.enabled ? "grid_on" : "grid_off"}
                        label={localChartFormatting.gridlines.enabled ? "Enabled" : "Disabled"}
                        tooltip="Include chosen colors on charts"
                        active={localChartFormatting.gridlines.enabled}
                        onClick={() => toggleLocalChartFormatting("gridlines", "enabled")}
                    />
                </div>
            </div>
            <div className="submit-buttons-container">
                <Button
                    type="action"
                    label="Cancel"
                    disabled={isLoading}
                    onClick={() => {
                        dispatch(setDetailClass("settings"));
                        setLocalChartFormatting(chartFormatting);
                        setLocalColors(colors);
                    }}
                />
                <Button
                    type="action"
                    label="Submit"
                    disabled={isLoading}
                    onClick={() => handleSubmit()}
                />
            </div>

            {DetailFooter(dispatch)}
        </div>
    );
};


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGoogleFileNames, signIn, signOut } from "../../../../../state/slices/googleSlice";
import { checkAccessToken } from '../../../../../api/Google/AuthCheck';
import { toggleSnackbar } from "../../../../Snackbar/Snackbar";
import { setGoogleData } from '../../../../../state/slices/googleSlice';
import Button from "../../../../Button/Button";

import { loadGooglePicker } from '../../../../../api/Google/GooglePicker';
import { openPopup } from "../../../../Popup/Popup";

const GoogleUploadButton = () => {
  const [auth, setAuth] = useState(null);
  const dispatch = useDispatch();
  const isSignedIn = useSelector((state) => state.google.isSignedIn);
  const [isLoading, setIsLoading] = useState(false);

  // Upload Sheets
  const onUploadClick = async () => {
    toggleSnackbar(dispatch, "info", "Connecting to Google...");
    setIsLoading(true);
    let accessToken = await checkAccessToken();
    if (accessToken) {
      toggleSnackbar(dispatch, "complete", "Connected to Google");
      const newData = await loadGooglePicker(accessToken);
      setIsLoading(false);
      if (newData && newData !== "cancel") {
        dispatch(setGoogleData(newData));
        openPopup(dispatch, "google");
      }
      else if (newData === "cancel") {
        toggleSnackbar(dispatch, "hidden", "Connected to Google");
      } else {
        toggleSnackbar(dispatch, "error", "Error Refreshing Sheets");
      }

    }
    else {
      toggleSnackbar(dispatch, "error", "Login credentials error, please sign in again");
      dispatch(signOut());
    }

  };


  return (isSignedIn &&
    <Button
      google
      type="short"
      disabled={isLoading}
      onClick={onUploadClick}
      label={`${isLoading ? "Uploading..." : "Upload Sheets"}`} />);

};

export default GoogleUploadButton;
import { createSlice } from '@reduxjs/toolkit';

const uploadedFilesSlice = createSlice({
  name: 'uploadedFiles',
  initialState: {
    uploadedFileNames: [],
  },
  reducers: {
    setUploadedFileNames: (state, action) => {
      state.uploadedFileNames = action.payload;
    },
    addUploadedFileName: (state, action) => {
      state.uploadedFileNames.push(action.payload);
    },
    deleteUploadedFileName: (state, action) => {
      state.uploadedFileNames = state.uploadedFileNames.filter(file => file !== action.payload);
    }
  }
});

export const { setUploadedFileNames, addUploadedFileName, deleteUploadedFileName } = uploadedFilesSlice.actions;
export default uploadedFilesSlice.reducer;
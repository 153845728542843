import { createSlice } from '@reduxjs/toolkit';

const hubspotSlice = createSlice({
  name: 'hubspot',
  initialState: {
    formattedHubspotSources: ""
  },
  reducers: {
    setFormattedHubspotSources: (state, action) => {
      state.formattedHubspotSources = action.payload;
    }
  },
});

export const {
  setFormattedHubspotSources
} = hubspotSlice.actions;
export default hubspotSlice.reducer;
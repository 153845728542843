import React from 'react';
import './style/snackbar.css';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbarStatus, setSnackbarContent, setSnackbarTimeout, clearSnackbarTimeout } from '../../state/slices/snackbarSlice';

export const toggleSnackbar = (dispatch, status, content = '') => {
    dispatch(clearSnackbarTimeout());
    dispatch(setSnackbarStatus(status));
    dispatch(setSnackbarContent(content));
    if (status === "complete" || status === "error") {
        const timeout = setTimeout(() => {
            dispatch(setSnackbarStatus("hidden"));
        }, 3000);
        dispatch(setSnackbarTimeout(timeout));
    } else {
        const timeout = setTimeout(() => {
            dispatch(setSnackbarStatus("hidden"));
        }, 10000);
        dispatch(setSnackbarTimeout(timeout));
    }
};

const Snackbar = () => {
    const dispatch = useDispatch();
    const { snackbarStatus, snackbarContent } = useSelector(state => state.snackbar);

    return (
        <div className={`snackbar ${snackbarStatus}`}>
            <div className={`label`}>{snackbarContent}</div>
            <i className="material-icons-outlined" onClick={() => toggleSnackbar(dispatch, "hidden", snackbarContent)}>clear</i>
        </div>
    );
};

export default Snackbar;
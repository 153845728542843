import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import '../style/detail.css';
import { setThreads, setCurrentThreadID } from '../../../state/slices/threadsSlice.jsx';
import { DetailFooter } from '../Detail.jsx';
import Button from '../../Button/Button';
import { getAllThreads } from '../../../api/Ask';
import { setIsSendDisabled } from '../../../state/slices/utilitiesSlice.jsx';
import { getMessages } from '../../../api/Ask.jsx';
import { toggleSnackbar } from '../../Snackbar/Snackbar.jsx';
import { setMessageTree } from '../../../state/slices/messagesSlice.jsx';

export function HistoryDetail() {
    const dispatch = useDispatch();
    const { detailClass } = useSelector(state => state.sidemenu);
    const { threads, currentThreadID } = useSelector(state => state.threads);
    const displayTime = (datetimeString) => datetimeString ? new Date(datetimeString).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) : "No date";
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadCurrentThreadMessages = async () => {
            try {
                const match = matchPath('/chat/c/:threadId', window.location.pathname);
                const threadId = match?.params?.threadId;
                if (threadId && threadId !== currentThreadID) {
                    // console.log("changing thread from", currentThreadID, "to", threadId);
                    changeThread(threadId);
                }
            } catch (error) {
                console.error(error);
                toggleSnackbar(dispatch, "error", "Error matching path");
            }
        };

        const getThreads = async () => {
            loadCurrentThreadMessages();
            const t = await getAllThreads();
            dispatch(setThreads(t));
            setIsLoading(false);
        }
        setIsLoading(true);
        getThreads();

    }, []);

    useEffect(() => {
        if (currentThreadID) {
            const currentUrl = window.location.pathname;
            const newUrl = currentUrl.includes('/c/')
                ? currentUrl.replace(/\/c\/[^/]+/, `/c/${currentThreadID}`)
                : `${currentUrl}/c/${currentThreadID}`;
            window.history.pushState(null, '', newUrl);
        }
    }, [currentThreadID]);


    const changeThread = async (thread_id) => {
        dispatch(setCurrentThreadID(thread_id));
        dispatch(setIsSendDisabled(true));
        toggleSnackbar(dispatch, "info", "Loading messages for thread...")
        try {
            const response = await getMessages(thread_id);
            const messageTree = response.messages
            dispatch(setMessageTree(messageTree));
            toggleSnackbar(dispatch, "complete", "Messages loaded!")
        }
        catch (error) {
            console.error("error getting messages: ", error)
            dispatch(setCurrentThreadID(null));
            toggleSnackbar(dispatch, "error", "Error loading messages. Please try again.")
        }

        dispatch(setIsSendDisabled(false));

    }

    const categorizeThreads = (threads) => {
        const now = new Date();
        const today = new Date(now);
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
        const pastWeek = new Date(now);
        pastWeek.setDate(now.getDate() - 7);
        const pastMonth = new Date(now);
        pastMonth.setMonth(now.getMonth() - 1);

        const categories = {
            "Today": [],
            "Yesterday": [],
            "Past Week": [],
            "Past Month": [],
            "Before": []
        };

        threads.forEach((thread) => {
            const threadDate = new Date(thread.updated_at);
            if (threadDate >= today) {
                categories["Today"].push(thread);
            } else if (threadDate >= yesterday) {
                categories["Yesterday"].push(thread);
            } else if (threadDate >= pastWeek) {
                categories["Past Week"].push(thread);
            } else if (threadDate >= pastMonth) {
                categories["Past Month"].push(thread);
            } else {
                categories["Before"].push(thread);
            }
        });

        return categories;
    };

    const categorizedThreads = categorizeThreads(threads);
    return (
        <div className={`detail small ${detailClass === "history" ? detailClass : 'hidden'}`}>
            <div className={"content"}>
                {threads.length === 0 && !isLoading &&
                    <>
                        <h1>History</h1>
                        <h3> no history yet, start a conversation with Maester to add to this window! </h3>
                    </>
                }
                {isLoading &&
                    <h1>Loading History...</h1>
                }
                <div style={{ overflowY: "auto", paddingBottom: '5rem' }}>
                    {Object.entries(categorizedThreads)
                        .filter(([category, threads]) => threads.length > 0) // Filter out empty categories
                        .map(([category, threads]) => (
                            <div key={category}>
                                <h1>{category}</h1>
                                {threads.map((thread) => (
                                    <Button key={thread.thread_id}
                                        type="medium"
                                        active={thread.thread_id === currentThreadID}
                                        archived={thread.archived}
                                        startIcon={"history"}
                                        onClick={() => { if (thread.thread_id !== currentThreadID) { changeThread(thread.thread_id); } }}
                                        label={thread.summary}
                                        subtitle={displayTime(thread.updated_at)}
                                        tooltip={thread.summary} />
                                ))}
                            </div>
                        ))}
                </div>
            </div>
            {DetailFooter(dispatch)}
        </div>
    );
};


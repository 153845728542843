import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/popup.css';
import { closePopup } from '../Popup';
import Button from '../../Button/Button';
import { toggleSnackbar } from '../../Snackbar/Snackbar';
import { setSendWithContextFiles } from '../../../state/slices/contextSlice';

export function ContextPopup() {
    const dispatch = useDispatch();
    const { isPopupOpen, popupClass } = useSelector(state => state.popup);
    const { sendWithContextFiles } = useSelector(state => state.context);
    const { uploadedFileNames } = useSelector(state => state.uploadedFiles);
    const { googleFileNames } = useSelector(state => state.google);
    const { formattedHubspotSources } = useSelector(state => state.hubspot);

    function toggleFileSelection(filename) {
        sendWithContextFiles.includes(filename) ? dispatch(setSendWithContextFiles(sendWithContextFiles.filter(file => file !== filename))) : dispatch(setSendWithContextFiles([...sendWithContextFiles, filename]))
    }

    function renderContextFiles() {
        const uploadedFiles = uploadedFileNames.map((filename, index) => {
            return (
                <Button
                    active={sendWithContextFiles.includes(filename)}
                    key={"file" + index}
                    startIcon={'description'}
                    type={'medium'}
                    label={filename}
                    tooltip={filename}
                    className={`button medium`}
                    onClick={() => toggleFileSelection(filename)}
                />
            );
        });

        const googleFiles = googleFileNames.map((filename, index) => {
            const displayName = filename.startsWith("googlesheets_") ? filename.replace("googlesheets_", "").replace("_", " ").replace(".csv", "") : filename;
            const [displaySheetName, displayTabName] = displayName.split('-MAESTERTABNAME-');
            return (
                <Button
                    google
                    active={sendWithContextFiles.includes(filename)}
                    key={"file" + index}
                    type={'medium'}
                    label={displaySheetName}
                    subtitle={displayTabName}
                    tooltip={displaySheetName}
                    className={`button medium`}
                    onClick={() => toggleFileSelection(filename)}
                />
            );
        });

        const hubspotFiles = formattedHubspotSources.map((filename, index) => {
            const displayName = filename.replace("hubspot_", "").replace(".csv", "").replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase()) + " Data Source";
            return (
            <Button
                hubspot
                active={sendWithContextFiles.includes(filename)}
                key={"file" + index}
                type={'medium'}
                label={displayName}
                tooltip={displayName}
                className={`button medium`}
                onClick={() => toggleFileSelection(filename)}
            />
            );
        });

        return [uploadedFiles, googleFiles, hubspotFiles];
    };

    return (
        <div className={`popup ${popupClass} ${isPopupOpen ? "" : "hidden"}`} onClick={(e) => e.stopPropagation()}>
            <div className="header">
                <div className="title"> Which files would you like Maester to analyze with your request? </div>
            </div>
            <div className="content">
                <div className="container">
                    {renderContextFiles()}
                </div>
            </div>
            <div className="popup-footer">
                <Button type="action" label={"Confirm"} startIcon={"check"} onClick={() => { closePopup(dispatch); }} />
            </div>
        </div>
    );
}
import { createSlice } from '@reduxjs/toolkit';
import process from 'process';

const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],
    notify: false,
    presetMessage: '',
    messageTree: [],
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setMessageTree: (state, action) => {
      state.messageTree = action.payload;
      const formattedMessages = treeParser(action.payload);
      state.messages = formattedMessages;
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    editMessage: (state, action) => {
      const { message_id, content } = action.payload;
      const messageIndex = state.messages.findIndex(message => message.message_id === message_id);
      if (messageIndex !== -1) {
        state.messages[messageIndex].content = content;
      }
    },
    updateThumbsUp: (state, action) => {
      const { messageId, isThumbsUp } = action.payload;
      const message = state.messages.find(msg => msg.message_id === messageId);
      if (message) {
        message.is_thumbs_up = isThumbsUp;
      }
    },
    toggleNotify: (state) => {
      state.notify = !state.notify;
    },
    setPresetMessage: (state, action) => {
      state.presetMessage = action.payload;
    },
    editMessageProperty: (state, action) => {
      const { message_id, changedObject } = action.payload;
      const editPropertyInTree = (messages) => {
        return messages.map(message => {
          if (message.message_id === message_id) {
            return { ...message, ...mergeDeep(message, changedObject) };
          }
          if (message.children && message.children.length > 0) {
            return { ...message, children: editPropertyInTree(message.children) };
          }
          return message;
        });
      };
      state.messageTree = editPropertyInTree(state.messageTree);
      const formattedMessages = treeParser(state.messageTree);
      state.messages = formattedMessages;
    }
  },
});

const findUserMessageByRunId = (messages, runId) => {
  for (const message of messages) {
    if (message.run_id === runId && message.role === 'user') {
      return message;
    }
    if (message.children && message.children.length > 0) {
      const foundMessage = findUserMessageByRunId(message.children, runId);
      if (foundMessage) {
        return foundMessage;
      }
    }
  }
  return null;
};

const parseMessageTree = (messageTree) => {
  const formattedMessages = [];

  const traverseMessages = (messages) => {
    messages.forEach(message => {
      const { children, ...rest } = message; // Destructure to exclude `children`
      formattedMessages.push({
        ...rest,
        message_id: message.message_id + (message.image_data ? "-image" : ""),
        content: message.content ?? message.response,
        role: message.role ?? (message.model !== null ? "assistant" : "user"),
        type: message.type ?? "text",
        message_type: message.message_type ?? null,
        is_thumbs_up: message.is_thumbs_up ?? null,
        image_data: message.image_data ?? null,
      });

      if (message.children && message.children.length > 0) {
        // To see only the child that includes selected assistant
        if (message.children.length === 1) {
          traverseMessages(message.children)
        }
        else {
          traverseMessages(message.children.filter(child => child.metadata && child.metadata.is_selected_assistant));
        };
        // //To see all children
        // traverseMessages(message.children);
      }
    });
  };

  traverseMessages(messageTree);
  return formattedMessages;
};

const parseMessageTreeDEV = (messageTree) => {
  const formattedMessages = [];
  const queue = [...messageTree];
  while (queue.length > 0) {
    const message = queue.shift();
    const { children, ...rest } = message; // Destructure to exclude `children`
    formattedMessages.push({
      ...rest,
      message_id: message.message_id + (message.image_data ? "-image" : ""),
      content: message.content ?? message.response,
      role: message.role ?? (message.model !== null ? "assistant" : "user"),
      type: message.type ?? "text",
      message_type: message.message_type ?? null,
      is_thumbs_up: message.is_thumbs_up ?? null,
      image_data: message.image_data ?? null,
    });

    if (message.children && message.children.length > 0) {
      queue.push(...message.children);
    }
  }

  return formattedMessages;
};

const treeParser = (messageTree) => {
  // DEV mode is used to see all messages in the tree
  // if (process.env.REACT_APP_ENV === "dev") {
  //   return parseMessageTreeDEV(messageTree);
  // } else {
    return parseMessageTree(messageTree);
  // }
};


// Helper function to perform a deep merge of two objects
const mergeDeep = (target, source) => {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], mergeDeep(target[key], source[key]));
    }
  }
  Object.assign(target || {}, source);
  return target;
};

export const { setMessages, addMessage, editMessage, toggleNotify, setPresetMessage, setMessageTree, editMessageProperty } = messagesSlice.actions;
export { findUserMessageByRunId };
export default messagesSlice.reducer;
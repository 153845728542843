import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signIn, signOut } from "../../../../../state/slices/googleSlice";
import process from 'process';
import { getGoogleAccessTokenFromAuthCode } from '../../../../../api/Google/Token';
import { checkAccessToken } from '../../../../../api/Google/AuthCheck';
import Button from "../../../../Button/Button";

const GoogleAuthButton = () => {
  const [auth, setAuth] = useState(null);
  const dispatch = useDispatch();
  const { isSignedIn, accessToken } = useSelector((state) => state.google);
  const user = useSelector((state) => state.utilities.user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const clientId = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;

    const initializeGIS = () => {
      const authInstance = window.google.accounts.oauth2.initCodeClient({
        client_id: clientId,
        scope: "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets",
        ux_mode: 'popup',
        redirect_uri: "postmessage",
        callback: async (response) => {
          if (response.error) {
            console.error("Error during authentication", response.error);
            return "cancel";
          }
          // Use the provided function to get the access token from the auth code
          await getGoogleAccessTokenFromAuthCode(response.code, user);
          onAuthChange(true, response.code);
        },
      });

      setAuth(authInstance);
    };

    const checkAndInitialize = async () => {
      const existingToken = await checkAccessToken();
      if (existingToken) {
        onAuthChange(true, existingToken);
      } else {
        if (window.google && window.google.accounts) {
          initializeGIS();
        } else {
          window.addEventListener("load", initializeGIS);
        }
      }
    };

    if (user) {
      setIsLoading(true);
      checkAndInitialize();
      setIsLoading(false);
    }

    return () => {
      window.removeEventListener("load", initializeGIS);
    };
  }, [user]);

  // Handle authentication state changes
  const onAuthChange = (isSignedIn, accessToken) => {
    if (isSignedIn && accessToken) {
      dispatch(signIn(accessToken));
    } else {
      dispatch(signOut());
    }
  };

  // Sign in the user
  const onSignInClick = async () => {
    setIsLoading(true);
    if (auth) {
      auth.requestCode();
    } else {
      const existingToken = await checkAccessToken();
      if (existingToken) {
        onAuthChange(true, existingToken);
      } else {
        // Initialize GIS if auth doesn't exist
        const clientId = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;
        const authInstance = window.google.accounts.oauth2.initCodeClient({
          client_id: clientId,
          scope: "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets",
          ux_mode: 'popup',
          redirect_uri: "postmessage",
          callback: async (response) => {
            if (response.error) {
              console.error("Error during authentication", response.error);
              setIsLoading(false);
              return;
            }
            // Use the provided function to get the access token from the auth code
            await getGoogleAccessTokenFromAuthCode(response.code, user);
            onAuthChange(true, response.code);
          },
        });

        setAuth(authInstance);
        authInstance.requestCode();
      }
    }
    setIsLoading(false);
  };

  // Sign out the user
  const onSignOutClick = () => {
    onAuthChange(false);
  };

  // Render the appropriate button based on the sign-in state
  const renderAuthButton = () => {
    if (isSignedIn) {
      // return <Button type="short" google disabled={isLoading} onClick={onSignOutClick} label={`${isLoading ? "Connecting" : "Sign Out"}`} />;
      return null;
    } else {
      return <Button type="short" google disabled={isLoading} onClick={onSignInClick} label={`${isLoading ? "Connecting" : "Sign In"}`} />;
    }
  };

  return renderAuthButton();
};

export default GoogleAuthButton;
import { post } from 'aws-amplify/api';
import { getJwtToken } from "../../functions/useJwtToken"

export const getGoogleAccessTokenFromAuthCode = async (authCode, user) => {
  const jwtToken = await getJwtToken();
  if (!jwtToken) {
    console.error("JWT Token Fetch Error")
    return null
  }
  try {
    const responseBody = await (await post({
      apiName: 'MaesterV3API',
      path: '/api/v3/google/token',
      options: {
        headers: { Authorization: jwtToken },
        body: {
          authorization_code: authCode,
          redirect_uri: "postmessage",
          user_role: user.role,
          organization: user.organization
        }
      }
    }).response).body.json();
    // Google Step 6: If successful, save the access token and open the picker
    if (responseBody.access_token) {
      // loadGooglePicker(responseBody.access_token);
      console.log("getGoogleAccessTokenFromAuthCode: Access token found,", responseBody.access_token);
      return responseBody.access_token;
    }
  } catch (error) {
    console.error("Handle Auth Failed", error);
  }
};
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/popup.css';
import { closePopup } from '../Popup';
import { toggleSnackbar } from '../../Snackbar/Snackbar';
import { setFeedback } from '../../../state/slices/popupSlice';
import { handleFeedback } from "../../../api/Feedback";
import Button from '../../Button/Button';

export function RequestSourcePopup() {

    const dispatch = useDispatch();
    const { isPopupOpen, feedback } = useSelector(state => state.popup);

    const requestSource = async () => {
        if (feedback !== "") {
            toggleSnackbar(dispatch, "info", "Sending source request...");
            handleFeedback({
                feedback: "SOURCE REQUEST\n" + feedback,
            })
                .then(response => {
                    if (response) {
                        toggleSnackbar(dispatch, "complete", "Feedback sent successfully!");
                    } else {
                        toggleSnackbar(dispatch, "error", "Feedback failed to send. Please try again later.");
                    }
                })
                .catch(error => {
                    console.error('Failed to send feedback:', error);
                    toggleSnackbar(dispatch, "error", "Feedback failed to send. Please try again later.");
                });
            closePopup(dispatch);
        } else{
            toggleSnackbar(dispatch, "error", "Please provide feedback before submitting.");
        };
        
    };

    return (
        <div className={`popup feedback ${!isPopupOpen && "hidden"}`} onClick={(e) => e.stopPropagation()}>
            <div className="header">
                <div className="title"> Request a Source </div>
            </div>
            <div className="content">
                <textarea
                    className="text-input"
                    placeholder='Which data source(s) would you like to see on Maester?'
                    value={feedback}
                    onChange={(e) => { e.preventDefault(); dispatch(setFeedback(e.target.value)); }}>
                </textarea>
            </div>
            <div className="popup-footer">
                <Button type="action" label={"Cancel"} onClick={() => { closePopup(dispatch); }} />
                <Button type="action" label={"Submit"} onClick={() => { requestSource(); }} />
            </div>
        </div>
    );
}
import { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

export const useJwtToken = () => {
  const [jwtToken, setJwtToken] = useState(null);

  const fetchJwtToken = async () => {
    try {
      const session = await fetchAuthSession();
      setJwtToken(session.tokens.idToken.toString());
      console.log('JWT token:', session.tokens.accessToken.toString());
      // console.log('ID token:', session.tokens.idToken.toString());
    } catch (error) {
      console.error("Failed to get JWT token", error);
    }
  }

  useEffect(() => {
    fetchJwtToken(); // Fetch the token immediately

    const intervalId = setInterval(fetchJwtToken, 15 * 60 * 1000); // Refresh the token every 15 minutes
    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);  // Runs only once after the initial render

  return jwtToken;
};

export const getJwtToken = async () => {
  try {
    const session = await fetchAuthSession();
    // console.log('JWT token:', session.tokens.accessToken.toString());
    return session.tokens.idToken.toString();
  } catch (error) {
    console.error("Failed to get JWT token", error);
    return null;
  }
}
import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const DataRefreshMessage = forwardRef((props, ref) => {
    const { refreshedFiles } = props;
    const formattedFilesList = [...new Set(refreshedFiles.map(file => {
        let filename = file.file_name;
        if (filename.startsWith("googlesheets_")) {
            filename = filename.replace("googlesheets_", "").replace("_", " ").replace(".csv", "").replace("-MAESTERTABNAME-", " ");
        } 
        if (filename.startsWith('hubspot_')) {
            const parts = filename.replace('.csv', '').split('_');
            const baseName = parts[0];
            const sourceName = parts[1];
            filename = `${baseName.charAt(0).toUpperCase() + baseName.slice(1)} ${sourceName.charAt(0).toUpperCase() + sourceName.slice(1)} Data Source`;
        }
        return filename;
    }))].join(', ');

    return (
        <div className="message-content-container data-update" >
            <div className="data-update">
                <span>&#x21bb;</span>
                <span>The following data sources have been refreshed since the last time you used this thread: <strong>{formattedFilesList}</strong></span>
            </div>
        </div>
    );
});

export default DataRefreshMessage;

import process from 'process';

export const loadGooglePicker = (accessToken) => {
  return new Promise((resolve, reject) => {
    const onPickerAPILoad = () => {
      const pickerInstance = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .setOAuthToken(accessToken)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
        .build();
      
      pickerInstance.setCallback((data) => {
        const action = data[window.google.picker.Response.ACTION];
        
        if (action === 'cancel') {
          pickerInstance.setVisible(false);
          resolve('cancel');
          return;
        }

        if (action === 'picked') {
          pickerInstance.setVisible(false);
          const fileId = data.docs[0].id;
          
          fetch(`https://sheets.googleapis.com/v4/spreadsheets/${fileId}?fields=sheets.properties.title`, {
            headers: { 'Authorization': `Bearer ${accessToken}` }
          })
          .then(response => response.json())
          .then(sheetsData => {
            const tabNames = sheetsData.sheets.map(sheet => sheet.properties.title);
            resolve({
              data: data,
              tabNames: tabNames,
              selectedTabNames: []
            });
          })
          .catch(reject);
        }
      });
      
      pickerInstance.setVisible(true);
    };

    window.gapi.load('picker', {
      callback: onPickerAPILoad,
      onerror: (error) => reject(error)
    });
  });
};
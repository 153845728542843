import { get, put } from 'aws-amplify/api';
import { getJwtToken } from '../../functions/useJwtToken';
//API Calls

export const getHubSpotObjectToFields = async () => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    const apiFields = await (await get({
        apiName: 'MaesterV3API',
        path: '/api/v3/hubspot/fields',
        options: {
            headers: { Authorization: jwtToken },
            body: { redirect_uri: window.location.origin + '/hubspot-callback' }
        }
    }).response).body.json();
    let allFields = {};
    let selectedFields = {};

    for (let object in apiFields) {
        allFields[object] = Object.keys(apiFields[object]);

        for (let field of Object.keys(apiFields[object])) {
            if (!selectedFields[object]) {
                selectedFields[object] = {};
            }
            selectedFields[object][field] = apiFields[object][field];
        }
    }
    return [allFields, selectedFields];
}

export const handleHubspotSubmit = async (selectedFields) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error");
        return null;
    }
    try {
        const response = await (await (put({
            apiName: 'MaesterV3API',
            path: '/api/v3/hubspot/fields',
            options: {
                headers: { Authorization: jwtToken },
                body: { fields: selectedFields }
            }
        })).response).body.json();
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
};
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setDetailClass, setSidemenuSize } from '../../state/slices/sidemenuSlice';
import TrendingTopics from '../TrendingTopics/TrendingTopics';
import './style/sidemenu.css';
import Logo from "../../assets/logo.png";
import Detail from './Detail';
import Button from '../Button/Button';
import { newChat } from '../MessageInput/MessageInput';

// For Reference: 
// const { detailClass, activeButton, isSidemenuCollapsed } = useSelector(state => state.sidemenu);
function Sidemenu() {
    const dispatch = useDispatch();
    const { detailClass, sidemenuSize } = useSelector(state => state.sidemenu);
    const { isSendDisabled } = useSelector(state => state.utilities);
    const containerSize = (() => {
        if (["formatting", "history", "settings"].includes(detailClass)) {
            return "details-container-small";
        } else if (detailClass !== null) {
            return "details-container-large";
        } else if (sidemenuSize === "small") {
            return "sidemenu-container-small";
        } else {
            return "sidemenu-container-large";
        }
    });


    return (
        <div>
            <div className={`sidemenu-container ${containerSize()}`}>
                <div className={`sidemenu ${sidemenuSize} ${detailClass ? 'collapsed' : ""}`} >
                    <div style={{ marginTop: "5rem", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className={`header ${sidemenuSize} ${detailClass ? 'collapsed' : ""}`}>
                            <img src={Logo} alt="Maester" className={`logo ${sidemenuSize} ${detailClass ? 'collapsed' : ""}`} onClick={() => { if (!detailClass) { dispatch(setSidemenuSize("large")) } }} />
                            <div className={`title ${sidemenuSize} ${detailClass ? 'collapsed' : ""}`} onClick={() => { if (!detailClass) { dispatch(setSidemenuSize("small")) } }}> maester </div>
                        </div>
                        <Button type={`sidemenu-button ${(detailClass || sidemenuSize === "small") ? 'small' : ""}`} tooltip="New Chat" startIcon="add_comment" label="New Chat" disabled={isSendDisabled} onClick={() => { newChat(dispatch) }} />
                        <Button type={`sidemenu-button ${(detailClass || sidemenuSize === "small") ? 'small' : "invisible"}`} tooltip="Trending Topics" startIcon="trending_up" onClick={() => { dispatch(setDetailClass(null)); dispatch(setSidemenuSize("large")); }} />
                        <TrendingTopics />
                    </div>
                    <div style={{ marginBottom: "2.4rem", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button type={`sidemenu-button ${(detailClass || sidemenuSize === "small") ? 'small' : ""}`} active={detailClass === "history"} tooltip="History" startIcon="history" label="History" onClick={() => dispatch(setDetailClass(detailClass === "history" ? null : "history"))} />
                        <Button type={`sidemenu-button ${(detailClass || sidemenuSize === "small") ? 'small' : ""}`} active={["manage-data", "file-context"].includes(detailClass)} tooltip="Manage Data" startIcon="hub" label="Manage Data" onClick={() => dispatch(setDetailClass(["manage-data", "file-context"].includes(detailClass) ? null : "manage-data"))} />
                        <Button type={`sidemenu-button ${(detailClass || sidemenuSize === "small") ? 'small' : ""}`} active={["settings", "intake", "keywords", "formatting"].includes(detailClass)} tooltip="Settings" startIcon="settings" label="Settings" onClick={() => dispatch(setDetailClass(["settings", "intake", "keywords", "formatting"].includes(detailClass) ? null : "settings"))} />
                    </div>
                </div>
                <Detail />
            </div>
        </div>
    );

}

export default Sidemenu


import { createSlice } from '@reduxjs/toolkit';

const modelSelectorSlice = createSlice({
  name: 'modelSelector',
  initialState: {
    selectedModels: [],
    temperature: 0.7,
  },
  reducers: {
    setSelectedModels: (state, action) => {
      const model = action.payload;
      const index = state.selectedModels.indexOf(model);
      if (index === -1) {
        state.selectedModels.push(model);
      } else {
        state.selectedModels.splice(index, 1);
      }
    },
    setTemperature: (state, action) => {
      state.temperature = action.payload;
    },
  },
});

export const { setSelectedModels, setTemperature } = modelSelectorSlice.actions;
export default modelSelectorSlice.reducer;
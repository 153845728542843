import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import Button from '../../Button/Button';
import logoSmall from '../../../assets/logo.png';
import { toggleSnackbar } from '../../Snackbar/Snackbar';
import { renderFiles } from './AssistantFileViewer/AssistantFileViewer';
import { openPopup } from '../../Popup/Popup';
import { rateAnswer } from '../../../api/RateAnswer';
import ValidateButton from '../Validate/ValidateButton/ValidateButton';
import ValidateSection from '../Validate/ValidateSection/ValidateSection';
import './style/assistant-message.scss';

const AssistantMessage = React.forwardRef(({ messageObject, showButtonBar = true, showHeader }, ref) => {
    const dispatch = useDispatch();
    const { content,
        message_id,
        type,
        message_type,
        metadata,
        image_data,
        output_files,
        model,
        created_at,
        is_thumbs_up } = messageObject;
    const [localThumbsUp, setLocalThumbsUp] = useState(is_thumbs_up || null);
    const { currentThreadID } = useSelector(state => state.threads);
    const [validationList, setValidationList] = useState([]);
    const [showValidation, setShowValidation] = useState(false);
    const [validationStatus, setValidationStatus] = useState(null);
    const [activeGroup, setActiveGroup] = useState(0);

    const handleThumbClick = async (messageId, isThumbUp) => {
        openPopup(dispatch, isThumbUp ? "thumbs-up" : "thumbs-down");
        setLocalThumbsUp(isThumbUp);
        rateAnswer(currentThreadID, messageId, isThumbUp);
    };

    const copyImageToClipboard = async (image_data) => {
        try {
            const response = await fetch(image_data);
            const blob = await response.blob();
            const clipboardItem = new ClipboardItem({ [blob.type]: blob });
            await navigator.clipboard.write([clipboardItem]);
            toggleSnackbar(dispatch, "complete", "Message copied");
        } catch (error) {
            toggleSnackbar(dispatch, "error", "Error copying image to clipboard");
        }
    };

    const renderButtonBar = () => (
        <div className="message-button-bar">
            <Button
                type="icon-small"
                startIcon="content_copy"
                onClick={() => {
                    if (image_data) {
                        copyImageToClipboard(image_data)
                    } else {
                        navigator.clipboard.writeText(content);
                    };
                    toggleSnackbar(dispatch, "complete", "Message copied");
                }}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                    type="icon-small"
                    startIcon="thumb_up"
                    buttonStyle={{
                        opacity: localThumbsUp === true ? 1 : localThumbsUp === null ? 1 : 0.2,
                        pointerEvents: localThumbsUp === null ? 'auto' : 'none',
                    }}
                    iconStyle={localThumbsUp === true ? { color: "var(--green-color)" } : {}}
                    onClick={() => handleThumbClick(message_id, true)}
                />
                <Button
                    type="icon-small"
                    startIcon="thumb_down"
                    buttonStyle={{
                        opacity: localThumbsUp === false ? 1 : localThumbsUp === null ? 1 : 0.2,
                        pointerEvents: localThumbsUp === null ? 'auto' : 'none',
                    }}
                    iconStyle={localThumbsUp === false ? { color: "var(--red-color)" } : {}}
                    onClick={() => handleThumbClick(message_id, false)}
                />
            </div>
        </div>
    );

    const renderTextMessage = () => (
        <div className='message-container'>
            <div className="message-content-container maester" ref={ref}>
                {showHeader && <img className="message-header maester icon" src={logoSmall} />}
                {/* {
                    (metadata && metadata.is_selected_assistant && process.env.REACT_APP_ENV === "dev") ? (
                        <strong style={{ "color": "var(--primary-color)" }}>{model} (earliest, created at {new Date(created_at).toLocaleString()})</strong>
                    ) : (
                        (created_at && process.env.REACT_APP_ENV === "dev" && !["assistant-loading-id", "assistant-error"].includes(message_id)) ?
                            <span style={{ "color": "var(--text-color)" }}>{model} (created at {new Date(created_at).toLocaleString()})</span>
                            :
                            null
                    )
                } */}
                <div className="message-text maester">
                    <ReactMarkdown remarkPlugins={[remarkGfm]} >
                        {content && (content).replace("-MAESTERTABNAME-", " ")}
                    </ReactMarkdown>
                    {ref && !["assistant-loading-id", "assistant-error"].includes(message_id) &&
                        validationList &&
                        <ValidateSection 
                            messageObject={messageObject} 
                            validationList={validationList} 
                            setValidationList={setValidationList} 
                            showValidation={showValidation} 
                            setShowValidation={setShowValidation} 
                            validationStatus={validationStatus} 
                            setValidationStatus={setValidationStatus} 
                            activeGroup={activeGroup} 
                            setActiveGroup={setActiveGroup} 
                        />}
                    {renderFiles(message_id, output_files)}
                </div>
                {message_id !== "assistant-loading-id" && showButtonBar && renderButtonBar()}
            </div>
            {ref && !["assistant-loading-id", "assistant-error"].includes(message_id) && <ValidateButton 
                            messageObject={messageObject} 
                            validationList={validationList} 
                            setValidationList={setValidationList} 
                            showValidation={showValidation} 
                            setShowValidation={setShowValidation} 
                            validationStatus={validationStatus} 
                            setValidationStatus={setValidationStatus} 
                            activeGroup={activeGroup} 
                            setActiveGroup={setActiveGroup} 
                        />}
        </div>
    );

    return renderTextMessage();
});

export default AssistantMessage;

import { createSlice } from '@reduxjs/toolkit';

const exampleQuestionsSlice = createSlice({
  name: 'exampleQuestions',
  initialState: {
    exampleQuestions: [],
    questionsLoaded: false,
  },
  reducers: {
    setExampleQuestions: (state, action) => {
      state.exampleQuestions = action.payload;
    },
    setQuestionsLoaded: (state, action) => {
      state.questionsLoaded = action.payload;
    },
  },
});

export const { setExampleQuestions, setQuestionsLoaded } = exampleQuestionsSlice.actions;
export default exampleQuestionsSlice.reducer;
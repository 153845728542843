import React from 'react';
import './style/button.css';
import { googleSheetsLogo } from "../../assets/icons/googleSheetsLogo";
import { hubspotLogo } from "../../assets/icons/hubspotLogo";

const Button = (props) => {
    const { startIcon, endIcon1, endIcon2, google, hubspot, endFunction1, endFunction2, label, subtitle, onClick, type, iconStyle, active, archived, tooltip, disabled, buttonStyle, labelStyle, asLabel = false, asDiv = false } = props;
    const buttonClassName = [
        'button',
        active && 'active',
        archived && 'archived',
        disabled && 'disabled',
        asLabel && 'label',
        type
    ].filter(Boolean).join(' ');

    const Component = asDiv ? 'div' : 'button';

    return (
        <Component className={buttonClassName} style={buttonStyle} title={tooltip} onClick={onClick}>
            {startIcon && !["small", "action"].includes(type) && <i className={`material-icons-outlined`} style={iconStyle}>{startIcon}</i>}
            {google && <i className="material-icons-outlined sheets"> {googleSheetsLogo} </i>}
            {hubspot && <i className="material-icons-outlined hubspot"> {hubspotLogo} </i>}
            {(label || subtitle) && !["icon", "icon-small", "icon-medium"].includes(type) &&
                <div className="label-container">
                    {label &&
                        <span style={labelStyle} className={`label`}>
                            {label}
                        </span>
                    }
                    {subtitle && !["small", "action"].includes(type) &&
                        <span className={`subtitle`}>
                            {subtitle}
                        </span>
                    }
                </div>
            }
            {endIcon1 && type === "long" &&
                <div className='end-icons-container'>
                    <i className="material-icons-outlined clickable" onClick={endFunction1}>{endIcon1}</i>
                    {endIcon1 && endIcon2 && type === "long" && <i className="material-icons-outlined clickable" onClick={endFunction2}>{endIcon2}</i>}
                </div>
            }
        </Component>
    );
};

export default Button;
import { post } from 'aws-amplify/api';
import { getJwtToken } from '../functions/useJwtToken';

export const rateAnswer = async (threadID, message_id, isThumbUp) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error('JWT Token Fetch Error');
        return null;
    }
    await post({
        apiName: 'MaesterV3API',
        path: '/api/v3/rate-answer',
        options: {
            headers: { Authorization: jwtToken },
            body: {
                thread_id: threadID,
                message_id: message_id,
                is_thumbs_up: isThumbUp,
            }
        }
    }).response.catch((error) => {
        console.error(error);
        return false;
    });
    return true;
};
import { get, put, del } from 'aws-amplify/api';
import { getJwtToken } from "../functions/useJwtToken"
//API Calls
export const getFileNames = async () => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    //runs on page load to get all file names
    try {
        const fileKeys = await (await get({
            apiName: 'MaesterV3API',
            path: '/api/v3/files',
            options: {
                headers: { Authorization: jwtToken }
            }
        }).response).body.json();
        // TODO maybe we want to avoid giving our S3 structure to frontend, and should just give the file name from the backend
        return (fileNameSorter(fileKeys.content.map(result => result.split('/').slice(-1)[0])));
    } catch (error) {
        console.error('Error getting file names:', error);
        return ([[], [], []]);
    }
};

//Utility
//Make sure you build in something that moves things to the right place, this is maybe useful for the first load
const fileNameSorter = (fileNames) => {
    let otherFiles = [];
    let googleFiles = [];
    let hubspotFiles = [];
    fileNames.forEach(file => {
        if (file.toLowerCase().startsWith('hubspot_')) {
            hubspotFiles.push(file)
        }
        else if (file.toLowerCase().startsWith('googlesheets_')) {
            googleFiles.push(file)
        }
        else {
            otherFiles.push(file)
        }
    });
    return [otherFiles, googleFiles, hubspotFiles]
};

export const handleFilesUpload = async (formData) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    const resultBody = await (await put({
        apiName: 'MaesterV3API',
        path: '/api/v3/files',
        options: {
            headers: { Authorization: jwtToken },
            body: formData
        }
    }).response).body.json();
    return resultBody;
};

export const handleDeleteFile = async (fileNameToDelete) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    const response = await del({
        apiName: 'MaesterV3API',
        path: '/api/v3/files',
        options: {
            headers: { Authorization: jwtToken },
            queryParams: { filename: encodeURIComponent(fileNameToDelete) }
        }
    }).response;

    const responseBody = await response.body.json();

    return responseBody;
};


export const getAssistantFile = async ({ thread_id, message_id, filename }) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        const response = await get({
            apiName: 'MaesterV3API',
            path: '/api/v3/files',
            options: {
                headers: { Authorization: jwtToken },
                queryParams: {
                    thread_id,
                    message_id,
                    filename
                }
            }
        }).response;
        return response.body.json();
    } catch (error) {
        console.error("Failed to get File", error);
        return null;
    }
};


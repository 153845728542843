import { createSlice } from '@reduxjs/toolkit';

const threadsSlice = createSlice({
  name: 'threads',
  initialState: {
    threads: [],
    currentThreadID: null,
    currentThreadArchived: false,
  },
  reducers: {
    setThreads: (state, action) => {
      state.threads = action.payload;
    },
    setCurrentThreadID: (state, action) => {
      state.currentThreadID = action.payload;
    },
    setCurrentThreadArchived: (state, action) => {
      state.currentThreadArchived = action.payload;
    }
  },
});

export const { setThreads, setCurrentThreadID, setCurrentThreadArchived } = threadsSlice.actions;
export default threadsSlice.reducer;
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Message from '../Message/Message';
import './style/messages-panel.css';
import logoSmall from '../../assets/logo.png';
import Button from '../Button/Button';
import { setPresetMessage } from '../../state/slices/messagesSlice';
import { setExampleQuestions } from '../../state/slices/exampleQuestionsSlice';
import { getExampleQuestions } from '../../api/QuestionSuggestions';

const MessagesPanel = () => {
    const { messages } = useSelector(state => state.messages);
    const { user } = useSelector(state => state.utilities);
    const lastMessageRef = useRef(null);
    const panelRef = useRef(null);
    useEffect(() => {
        const panel = panelRef.current;
        if (panel && messages.length > 0 && messages[messages.length - 1].type !== 'data_update') {
            const isNearBottom = panel.scrollHeight - panel.scrollTop <= panel.clientHeight + 50;
            lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
            // Kinda works but not rly
            // if (isNearBottom || messages[messages.length - 1].role === 'user') {
            // }
        }
    }, [messages]);

    return (
        <div className='messages-panel' ref={panelRef}>
            {messages.length === 0 ? (
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                    <div className='welcome-container'>
                        <img className="welcome-logo" src={logoSmall} alt="Welcome Logo" />
                        <h1 className='welcome-message'>Hi {user.username ?? "there"}, how can I support you today?</h1>
                    </div>
                    <ExampleQuestions />
                </div>
            ) : (
                messages.map((message, index) => (
                    <Message
                        key={message.message_id + message.type + message.message_type + index}
                        messageObject={message}
                        showHeader={index === 0 || message.role !== messages[index - 1].role}
                        ref={index === messages.length - 1 ? lastMessageRef : null}
                    />
                ))
            )}
        </div>
    );
};

const ExampleQuestions = () => {
    const dispatch = useDispatch();
    const { exampleQuestions } = useSelector(state => state.exampleQuestions);
    const { isSendDisabled } = useSelector(state => state.utilities);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const getSuggested = async () => {
            setIsLoading(true);
            let suggested = await getExampleQuestions();
            if (suggested) {
                dispatch(setExampleQuestions(suggested));
            }
            setIsLoading(false);
        }
        getSuggested();

    }, [window.location.pathname]);

    return (
        <>
            {isLoading ? <h1 style={{ width: '100%', textAlign: "center", margin: "8rem 0" }}> Loading Example Questions... </h1>
                :

                <div className={`example-questions-container ${isSendDisabled ? "disabled" : ""}`}>
                    {exampleQuestions.map((question, index) => (
                        <div
                            key={index}
                            className='example-question'
                            onClick={() => dispatch(setPresetMessage(question))}
                        >
                            <div className='label' title={question}>
                                {question}
                            </div>
                            <Button type={"icon-small"} iconStyle={{ color: "var(--primary-color)" }} startIcon={"arrow_forward_ios"} />
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default MessagesPanel;
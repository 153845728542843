import React, { useState, useEffect } from 'react';
import { signIn, getCurrentUser } from "aws-amplify/auth";
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import App from '../../App/App';
import logoSmall from "../../assets/logo.png";
import "./style/Login.css";

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [signInFailed, setSignInFailed] = useState(false);
    const [signInLoading, setSignInLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => { 
        const checkUserSession = async () => {
            try {
                await getCurrentUser();
                navigate('/chat');
            } catch (error) {
                console.error('User not logged in', error);
            }
        };

        checkUserSession();
    }, []);

    const handleLogin = async () => {
        setSignInFailed(false);
        setSignInLoading(true);
        try {
            const output = await signIn({ username, password });
            if (output.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                navigate('/change-password', {
                    state: {
                        username,
                        tempPassword: password, // Pass the user object to the ChangePassword page
                    }
                });
            }
            else if (output.nextStep.signInStep === "DONE"){
                console.log('Signed in', output);
                setSignInLoading(false);
                navigate('/chat');
            }
            else{
                console.log('Error:', output);
                navigate('/login');
            }
            return (
                <App />
            )
        } catch (error) {
            setSignInFailed(true);
            setSignInLoading(false);
            console.error('Error signing in', error);
        }
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='login-container'>
            <div className='login'>
                <img src={logoSmall} alt="Maester" className={`logo`} />
                <form className='login-input-container'>
                    <input className='login-input' autoComplete="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                    <div className='password-input-container'>
                        <input className='login-input' autoComplete="current-password" type={showPassword ? "text" : "password"} name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleLogin();
                            }
                        }} />
                        <button className='password-button material-icons-outlined' onClick={(e) => { e.preventDefault(); handleTogglePassword(); }}>
                            {showPassword ? "visibility" : "visibility_off"}
                        </button>
                    </div>
                    <a className='forgot-password' href='/forgot-password'>Forgot Password?</a>
                </form>
                <button className='login-button' onClick={() => handleLogin()}>Login</button>
                <label className={`status loading ${signInLoading ? "" : "hidden"}`}> Logging in... </label>
                <label className={`status failed ${signInFailed ? "" : "hidden"}`}>Issues signing in.<br />Please check your login credentials and try again.</label>
                {/* <button className='login-button' onClick={handleLogOut}>Logout</button> */}
            </div>

        </div>
    );
}

export default Login;

import { configureStore } from '@reduxjs/toolkit';
import exampleQuestionsReducer from './slices/exampleQuestionsSlice';
import popupReducer from './slices/popupSlice';
import dataIntakeReducer from './slices/dataIntakeSlice';
import utilitiesReducer from './slices/utilitiesSlice';
import loginReducer from './slices/loginSlice';
import uploadedFilesReducer from './slices/uploadedFilesSlice';
import hubspotReducer from './slices/hubspotSlice';
import googleReducer from './slices/googleSlice';
import messagesReducer from './slices/messagesSlice';
import threadsReducer from './slices/threadsSlice';
import modelSelectorReducer from './slices/modelSelectorSlice';
import contextReducer from './slices/contextSlice';
import trendingTopicsReducer from './slices/trendingTopicsSlice';
import snackbarReducer from './slices/snackbarSlice';
import sidemenuReducer from './slices/sidemenuSlice';

const store = configureStore({
  reducer: {
    exampleQuestions: exampleQuestionsReducer,
    popup: popupReducer,
    dataIntake: dataIntakeReducer,
    utilities: utilitiesReducer,
    login: loginReducer,
    uploadedFiles: uploadedFilesReducer,
    hubspot: hubspotReducer,
    google: googleReducer,
    messages: messagesReducer,
    threads: threadsReducer,
    modelSelector: modelSelectorReducer,
    context: contextReducer,
    trendingTopics: trendingTopicsReducer,
    snackbar: snackbarReducer,
    sidemenu: sidemenuReducer
  },
  // Enable Redux DevTools
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
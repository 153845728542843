import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import './style/validate-section.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { editMessageProperty } from '../../../../state/slices/messagesSlice';
import AssistantMessage from '../../AssistantMessage/AssistantMessage';
import { changeSelectedMessage } from '../../../../api/Ask';
import logo from '../../../../assets/logo.png';

const ValidateSection = (props) => {
    const dispatch = useDispatch();
    const { messageObject, validationList, setValidationList, showValidation, setShowValidation, validationStatus, setValidationStatus, activeGroup, setActiveGroup } = props;
    const { currentThreadID } = useSelector(state => state.threads);
    const { messages, messageTree } = useSelector(state => state.messages);
    const sectionRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (sectionRef.current) {
                const section = sectionRef.current;
                if (showValidation) {
                    section.style.maxHeight = "calc(" + section.scrollHeight + 'px + 2rem)';
                } else {
                    sectionRef.current.style.maxHeight = 0;
                }
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [activeGroup, showValidation]);

    const handleSelectClick = async (message) => {
        dispatch(editMessageProperty({
            message_id: messages[messages.length - 1].message_id,
            changedObject: { metadata: { is_selected_assistant: false } }
        }));
        dispatch(editMessageProperty({
            message_id: message.message_id,
            changedObject: { metadata: { is_selected_assistant: true } }
        }));

        // console.log(messageTree, messages);
        changeSelectedMessage({ currentThreadID, runID: message.run_id, selectedAssistantMessageID: message.message_id });
        // console.log("response", response);
    };

    return (
        <div className={`validate-section ${showValidation ? "active" : "hidden"}`} ref={sectionRef}>
            {!(validationStatus === "agree") && (
                <div className="validation-tabs">
                    {validationList.map((message, index) => (
                        <button
                            key={index}
                            className={`validation-tab ${activeGroup === index ? 'active' : ''}`}
                            onClick={() => setActiveGroup(index)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem' }}>
                                {index === 0 ? "Original Approach" : `Approach ${index + 1}`}
                                {message.metadata.is_best_answer && <img src={logo} className="recommended-logo" alt="logo" />}
                            </div>
                        </button>
                    ))}
                </div>
            )}
            {validationList.map((message, index) => (
                <div key={index} className={`validation-group ${activeGroup === index ? 'active' : ''}`}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem', marginBottom: '1rem' }}>
                        {!(validationStatus === "agree") && <h3>{index === 0 ? "Original Approach" : `Approach ${index + 1}`}</h3>}
                        {message.metadata.is_best_answer && <img src={logo} className="recommended-logo" alt="logo" />}
                    </div>
                    <div className="group-content">
                        {!(validationStatus === "agree") && (
                            <div className="validation-object">
                                <AssistantMessage messageObject={message} showButtonBar={false} ref={null} />
                            </div>
                        )}
                        <div className="reasoning-trace">
                            <ReactMarkdown remarkPlugins={[remarkGfm]} >
                                {message.metadata.reasoning_trace && (message.metadata.reasoning_trace).replace("-MAESTERTABNAME-", " ")}
                            </ReactMarkdown>
                        </div>
                        {!(validationStatus === "agree") ? (
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end' }}>
                                <Button
                                    type="action"
                                    label="Select"
                                    onClick={() => handleSelectClick(message)}
                                />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end' }}>
                                <Button
                                    type="action"
                                    label="Close"
                                    onClick={() => {
                                        setShowValidation(false);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ValidateSection;
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/popup.css';
import { closePopup } from '../Popup';
import { RenderFullFile } from '../../Message/AssistantMessage/AssistantFileViewer/AssistantFileViewer';
import Button from '../../Button/Button';
import { toggleSnackbar } from '../../Snackbar/Snackbar';


export function FilePreviewPopup() {
    const dispatch = useDispatch();
    const { isPopupOpen, previewFile } = useSelector(state => state.popup);
    const { filename, mime_type, encoding, content } = previewFile;
    if (mime_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        toggleSnackbar(dispatch, "info", "Word files do not support previews. Please download the file to view.");
        closePopup(dispatch);
        return null;
    }
    return (
        <div className={`popup preview-file ${!isPopupOpen && "hidden"}`} onClick={(e) => e.stopPropagation()}>
            <div className="header">
                <div className="title"> File Preview for: {filename} </div>
            </div>
            <div className="content">
                {RenderFullFile(previewFile)}
            </div>
            <div className="popup-footer">
                <Button type="action" label={"Close"} onClick={() => { closePopup(dispatch); }} />
            </div>
        </div>
    );
}
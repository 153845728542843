import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../style/detail.css';
import { setDetailClass } from '../../../state/slices/sidemenuSlice.jsx';
import { setKeywords } from '../../../state/slices/dataIntakeSlice';
import { DetailFooter } from '../Detail.jsx';
import Button from '../../Button/Button.jsx';
import { setPreferences } from '../../../api/Explanation.jsx';
import { toggleSnackbar } from '../../Snackbar/Snackbar.jsx';

export function KeywordsDetail() {
    const dispatch = useDispatch();
    const { detailClass } = useSelector(state => state.sidemenu);
    const { chartFormatting, keywords, colors, generalContext } = useSelector(state => state.dataIntake);
    const { audio, theme } = useSelector(state => state.utilities);
    const [localKeywords, setLocalKeywords] = useState({});
    const [keywordsVisibility, setKeywordsVisibility] = useState({});
    const [newKeyword, setNewKeyword] = useState({ key: "", value: "" });

    //aesthetic, sets initial hidden state of defintions so hidden animation doesn't happen on first render
    useEffect(() => {
        if (detailClass !== "keywords") {
            const newVisibility = {};
            Object.keys(keywords).forEach(key => {
                newVisibility[key] = false;
            });
            setKeywordsVisibility(newVisibility);
            const timer = setTimeout(() => {
                setKeywordsVisibility({});
            }, 400);
            return () => clearTimeout(timer);
        }
    }, [detailClass]);


    useEffect(() => {
        setLocalKeywords(keywords);
    }, [keywords]);

    const handleNewKeywordChange = (field, value) => {
        setNewKeyword(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const toggleVisibility = (key) => {
        setKeywordsVisibility(prevState => ({
            ...prevState,
            [key]: key in prevState ? !prevState[key] : true
        }));
    };

    const handleKeywordChange = (key, newDefinition) => {
        setLocalKeywords(prevState => ({
            ...prevState,
            [key]: newDefinition
        }));
    };

    const handleSubmit = async () => {
        if (keywords !== localKeywords) {
            dispatch(setKeywords(localKeywords));
            toggleSnackbar(dispatch, "info", "Updating keywords...");
            try {
                const response = await setPreferences({ chart_formatting: chartFormatting, keywords: localKeywords, colors, audio, theme });
                if (response) {
                    toggleSnackbar(dispatch, "complete", "Keywords updated!");
                    dispatch(setDetailClass("settings"));
                } else {
                    toggleSnackbar(dispatch, "error", "Failed to update keywords");
                }
            } catch (error) {
                toggleSnackbar(dispatch, "error", "Failed to update keywords");
            }
        }
        dispatch(setDetailClass("settings"));
    };

    return (
        <div className={`detail ${detailClass === "keywords" ? detailClass : 'hidden'}`}>
            <div className='content'>
                <h1>Add a New Keyword</h1>
                <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column' }}>
                    <textarea
                        style={{
                            height: '4rem',
                            padding: '0.5rem 2rem',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            alignContent: 'center'
                        }}
                        value={newKeyword.key}
                        onChange={(e) => handleNewKeywordChange('key', e.target.value)}
                    />
                    <textarea
                        style={{ marginLeft: '3rem', width: '33.5rem', height: '16rem' }}
                        value={newKeyword.value}
                        onChange={(e) => handleNewKeywordChange('value', e.target.value)}
                    />
                    <div className="submit-buttons-container">
                        <Button
                            type="action"
                            label="Add"
                            disabled={!newKeyword.key || !newKeyword.value}
                            onClick={() => {
                                setLocalKeywords(prevState => ({
                                    ...prevState,
                                    [newKeyword.key]: newKeyword.value
                                }));
                                setNewKeyword({ key: "", value: "" });
                            }}
                        />
                    </div>
                </div>
                <h1>Keywords</h1>
                <div style={{ flex: "1 1 0%", overflowY: "auto", display: "flex", flexDirection: 'column' }}>
                    {Object.entries(localKeywords).reverse().map(([key, value]) => (
                        <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column' }} key={key}>
                            <Button
                                type="long"
                                startIcon={"bookmark"}
                                endIcon1={"edit"}
                                endIcon2={"delete"}
                                endFunction2={() => {
                                    const newKeywords = { ...localKeywords };
                                    delete newKeywords[key];
                                    setLocalKeywords(newKeywords);
                                }}
                                label={key}
                                onClick={() => toggleVisibility(key)}
                            />
                            <textarea
                                className={`definition ${key in keywordsVisibility ?
                                    (keywordsVisibility[key] ? 'expanded' : 'hidden') :
                                    'initial-hidden'
                                    }`}
                                value={value}
                                onChange={(e) => handleKeywordChange(key, e.target.value)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="submit-buttons-container">
                <Button
                    type="action"
                    label="Cancel"
                    onClick={() => {
                        setLocalKeywords(keywords);
                        dispatch(setDetailClass("settings"));
                    }}
                />
                <Button
                    type="action"
                    label="Submit"
                    onClick={handleSubmit}
                />
            </div>
            {DetailFooter(dispatch)}
        </div>
    );
}
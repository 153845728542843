import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button/Button';
import { toggleSnackbar } from '../../Snackbar/Snackbar';
import { setPresetMessage } from '../../../state/slices/messagesSlice';
import './style/user-message.scss';

const UserMessage = forwardRef((props, ref) => {
    const { messageObject, showHeader } = props;
    const { user } = useSelector(state => state.utilities);
    const { content } = messageObject;
    const dispatch = useDispatch();

    const renderButtonBar = () => (
        <div className="message-button-bar">
            <Button
                type="icon-small"
                startIcon="content_copy"
                onClick={() => {
                    navigator.clipboard.writeText(content);
                    toggleSnackbar(dispatch, "complete", "Message copied");
                }}
            />
            <Button
                type="icon-small"
                startIcon="low_priority"
                onClick={() => {
                    dispatch(setPresetMessage(content));
                    toggleSnackbar(dispatch, "complete", "Message echoed");
                }}
            />
        </div>
    );

    return (
        <div className="message-content-container user" ref={ref}>
            {showHeader && <div className="message-header user">{user.username ?? "User"}</div>}
            <div className="message-text user">{content}</div>
            {renderButtonBar()}
        </div>
    );
});

export default UserMessage;

import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/popup.css';
import { closePopup } from '../Popup';
import Button from '../../Button/Button';

export function HelpPopup() {
    const dispatch = useDispatch();
    const { isPopupOpen } = useSelector(state => state.popup);
    return (
        <div className={`popup help ${!isPopupOpen && "hidden"}`} onClick={(e) => e.stopPropagation()}>
            <div className="header">
                <div className="title"> How to Make the Most of Maester </div>
            </div>
            <iframe className="content" src="https://docs.google.com/document/d/e/2PACX-1vRgWKldzKmFfh2KGqqle5xvyeY06tysgPNsE9xd4KmM5ocuCIWUfGnDEe-egf72Nb7R9X9SeAZ7LOjL/pub?embedded=true" />
            <div className="popup-footer">
                <Button type="action" label={"Close"} startIcon={"check"} onClick={() => { closePopup(dispatch); }} />
            </div>
        </div>

    );
}
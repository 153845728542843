import { createSlice } from '@reduxjs/toolkit';

const sidemenuSlice = createSlice({
  name: 'sidemenu',
  initialState: {
    detailClass: null,
    isSidemenuCollapsed: false,
    sidemenuSize: false,
  },
  reducers: {
    setDetailClass: (state, action) => {
      state.detailClass =action.payload;
    },
    setIsSidemenuCollapsed: (state, action) => {
      state.isSidemenuCollapsed = action.payload;
    },
    setSidemenuSize: (state, action) => {
      state.sidemenuSize = action.payload;
    },
  },
});

export const {
  setDetailClass,
  setIsSidemenuCollapsed,
  setSidemenuSize,
} = sidemenuSlice.actions;
export default sidemenuSlice.reducer;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUploadedFileNames, addUploadedFileName, deleteUploadedFileName } from '../../../../state/slices/uploadedFilesSlice';
import { setIsSendDisabled } from '../../../../state/slices/utilitiesSlice';
import { setGoogleFileNames } from '../../../../state/slices/googleSlice';
import { handleFilesUpload, getFileNames, handleDeleteFile } from '../../../../api/Files.jsx';

import { toggleSnackbar } from '../../../Snackbar/Snackbar';
import { getAllThreads } from '../../../../api/Ask';
import { setThreads } from '../../../../state/slices/threadsSlice.jsx';
import Button from '../../../Button/Button';
import { setDetailClass } from '../../../../state/slices/sidemenuSlice.jsx';
import { setActiveContextFile } from '../../../../state/slices/contextSlice.jsx';
import { setFormattedHubspotSources } from '../../../../state/slices/hubspotSlice.jsx';


// const response = await (await [Function]).response;
// const responseBody = await response.body.json();
// console.log("responseBody", responseBody);
export function UploadedDataDetail({ initialActive, active }) {
    const dispatch = useDispatch();
    const { uploadedFileNames } = useSelector(state => state.uploadedFiles);
    const { googleFileNames } = useSelector(state => state.google);
    //On Startup
    useEffect(() => {
        const fetchFileNames = async () => {
            await getUploadedAndGoogleFileNames();
        };
        fetchFileNames();
    }, []);


    const getUploadedAndGoogleFileNames = async () => {
        try {
            const [uploadedFiles, googleFiles, hubspotFiles] = await getFileNames();
            dispatch(setUploadedFileNames(uploadedFiles));
            dispatch(setGoogleFileNames(googleFiles));
            const formattedHubspotFiles = hubspotFiles.map(file => file.endsWith('.csv') ? file : `${file}.csv`);
            dispatch(setFormattedHubspotSources(formattedHubspotFiles));
        } catch (error) {
            console.error('Error getting file names:', error);
        }
    }

    const processAndUploadFiles = async (event) => {
        const files = event.target.files;
        const newFileNames = [...uploadedFileNames, ...Array.from(files).map(file => file.name.replace(/[^\w. :-]/g, '').replace(/\.xlsx$/, '.csv'))];
        const validExtensions = ['.csv', '.xlsx'];
        const invalidSyntax = ["hubspot_", "googlesheets_"];
        const formData = new FormData();
        toggleSnackbar(dispatch, "info", "Processing...");
        for (let i = 0; i < files.length; i++) {
            const fileName = files[i].name;
            if (fileName.includes('(') || fileName.includes(')')) {
                toggleSnackbar(dispatch, "error", 'Please remove parentheses from: ' + fileName);
                return;
            }
            if (uploadedFileNames.includes(fileName)) {
                toggleSnackbar(dispatch, "error", 'A file with the name: ' + fileName + ' already exists. Please delete it and try again.'); //TODO: Give users the option to delete here
                return;
            }
            if (!validExtensions.includes(fileName.substring(fileName.lastIndexOf('.')))) {
                toggleSnackbar(dispatch, "error", "We only accept the file types: " + validExtensions);
                return;
            }
            if (invalidSyntax.some(syntax => fileName.startsWith(syntax))) {
                const syntax = invalidSyntax.find(syntax => fileName.startsWith(syntax));
                toggleSnackbar(dispatch, "error", "Please remove the prefix: " + syntax + " from " + fileName);
                return;
            }

            formData.append('file-' + i, files[i]);
        }
        toggleSnackbar(dispatch, "info", "Processing complete, now uploading...");
        const responseMessage = await handleFilesUpload(formData);
        if (responseMessage.failed_uploads === 0) {
            dispatch(setUploadedFileNames([...new Set([...newFileNames, ...uploadedFileNames])]));
            toggleSnackbar(dispatch, "complete", `Successfully uploaded!`);
        } else {
            toggleSnackbar(dispatch, "error", `Failed to upload files: ${responseMessage.failed_uploads}`);
            return;
        }
    }

    const deleteFile = async (fileNameToDelete) => {
        dispatch(setIsSendDisabled(true));
        try {
            toggleSnackbar(dispatch, "info", "Deleting file: " + fileNameToDelete);
            const responseBody = await handleDeleteFile(fileNameToDelete);
            if (responseBody.message.includes("successfully deleted")) {
                dispatch(deleteUploadedFileName(fileNameToDelete));
                toggleSnackbar(dispatch, "complete", "File " + fileNameToDelete + " successfully deleted!");
                var t = await getAllThreads();
                dispatch(setThreads(t));
            } else {
                toggleSnackbar(dispatch, "error", "Error deleting " + fileNameToDelete + ". Please try again.");
            }
        } catch (error) {
            console.error(error);
            toggleSnackbar(dispatch, "error", "Error deleting " + fileNameToDelete + ". Please try again.");
        } finally {
            dispatch(setIsSendDisabled(false));
            return;
        }
    };



    return (
        <div className={`datasource ${initialActive ? (active ? "" : "hidden") : "initial-hidden"}`}>
            <h1>Uploaded Data Options</h1>
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                <label style={{ cursor: 'pointer' }}>
                    <input type="file" hidden multiple onChange={(e) => { processAndUploadFiles(e); e.target.value = null; }} />
                    <Button
                        asDiv
                        startIcon={"upload_file"}
                        type="short"
                        label={`Upload New File`}
                    />
                </label>
            </div>
            {uploadedFileNames.length > 0 ? <h1>Uploaded Data</h1> : null}
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {uploadedFileNames.map((fileName, index) => {
                    return (
                        <Button
                            key={index}
                            type="long"
                            label={fileName}
                            startIcon="article"
                            endIcon1="delete"
                            endFunction1={() => {
                                deleteFile(fileName);
                            }}
                            endIcon2="edit"
                            endFunction2={() => {
                                dispatch(setActiveContextFile(fileName));
                                dispatch(setDetailClass("file-context"));
                            }}
                        />
                    )
                })}
            </div>
        </div>


    )

}
import { get, put } from 'aws-amplify/api';
import { getJwtToken } from "../../functions/useJwtToken"

export const connectHubspot = async () => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        await get({
            apiName: 'MaesterV3API',
            path: '/api/v3/hubspot/direct-export',
            options: {
                headers: { Authorization: jwtToken },
            }
        }).response;
        return true;
    } catch (error) {
        return false;
    }
}

export const handleHubspotRefresh = async () => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    put({
        apiName: 'MaesterV3API',
        path: '/api/v3/hubspot/direct-export',
        options: {
            headers: { Authorization: jwtToken },
            body: {}
        }
    }).response.then(response => {
        console.log(response);
    }).catch(error => {
        console.error(error);
    });
}
import { post } from 'aws-amplify/api';
import { getJwtToken } from "../functions/useJwtToken"

/**
 * 
 * @param {String} curentThreadID 
 * @param {String} message 
 * @param {[String]} selectedModels
 * @param {Float} temperature
 * @param {List} sendWithContextFiles
 *  
 * @returns {Promise<{thread_id: string, run_id: string}>} ID of thread and run that was created or retrieved
 * 
 */

export const sendMessage = async ({ currentThreadID, message, selectedModels, temperature, sendWithContextFiles, parent_message_id }) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        const bodyVar = sendWithContextFiles.length > 0 ?
            {
                prompt: message,
                thread_id: currentThreadID,
                models: selectedModels,
                temperature: temperature,
                user_specified_files: sendWithContextFiles,
                parent_message_id: parent_message_id
            }
            :
            {
                prompt: message,
                thread_id: currentThreadID,
                models: selectedModels,
                temperature: temperature,
                parent_message_id: parent_message_id
            };
        const response = await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: bodyVar
            }
        }).response;

        return response.body.json();
    } catch (error) {
        console.error("Failed to send message", error);
        return null;
    }
};

export const getResponse = async ({ thread_id, run_id, models, parent_message_id }) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        const response = await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: {
                    thread_id,
                    run_id,
                    models,
                    parent_message_id
                }
            }
        }).response;

        return response.body.json();
    } catch (error) {
        console.error("Failed to check for answer", error);
        return null;
    }
};

//API Calls
export const getAllThreads = async () => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    //runs on page load to get all file names
    try {
        const responseBody = await (await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: {
                    give: "me threads" //need a body for the post request, could be anything
                }
            }
        }).response).body.json();
        responseBody.sort((a, b) => {
            const dateA = a.updated_at || 0;
            const dateB = b.updated_at || 0;
            return dateB - dateA;
        });
        return responseBody;
    } catch (error) {
        console.error('Error getting threads:', error);
        return ([]);
    };
}

export const getMessages = async (thread_id) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    //runs on page load to get all messages
    try {
        const responseBody = await (await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: { thread_id: thread_id }
            }
        }).response).body.json();
        return responseBody;
    } catch (error) {
        console.error("Failed to get messages", error)
        return { "thread_id": thread_id, "messages": [] };
    }
};


export const answerValidation = async ({ currentThreadID, runID, organization }) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        const bodyVar =
        {
            thread_id: currentThreadID,
            run_id: runID,
            organization: organization,
            should_validate: true
        };
        const response = await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: bodyVar
            }
        }).response;

        return response.body.json();
    } catch (error) {
        console.error("Failed to send message", error);
        return null;
    }
};

export const changeSelectedMessage = async ({ currentThreadID, runID, selectedAssistantMessageID }) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        const bodyVar =
        {
            thread_id: currentThreadID,
            run_id: runID,
            selected_assistant_message_id: selectedAssistantMessageID,
        };
        const response = await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: bodyVar
            }
        }).response;

        return response.body.json();
    } catch (error) {
        console.error("Failed to change selected message", error);
        return null;
    }
};
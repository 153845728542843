import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGoogleFileNames, signIn, signOut } from "../../../../../state/slices/googleSlice";
import { checkAccessToken } from "../../../../../api/Google/AuthCheck";

import { toggleSnackbar } from "../../../../Snackbar/Snackbar";

import Button from "../../../../Button/Button";
import { refreshSheets } from "../../../../../api/Google/ManageSheets";


const GoogleRefreshButton = () => {
  const [auth, setAuth] = useState(null);
  const dispatch = useDispatch();
  const isSignedIn = useSelector((state) => state.google.isSignedIn);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initialRefreshSheets = async () => {
      setIsLoading(true);
      let accessToken = await checkAccessToken();
      if (accessToken) {
        const newSheets = await refreshSheets();
        setIsLoading(false);
        if (newSheets) {
          dispatch(setGoogleFileNames(newSheets));
        }
      }
    };
    initialRefreshSheets();
  }, []);

  // Refresh Sheets
  const onRefreshClick = async () => {
    toggleSnackbar(dispatch, "info", "Refreshing Sheets");
    setIsLoading(true);
    let accessToken = await checkAccessToken();
    if (accessToken) {
      toggleSnackbar(dispatch, "info", "Refreshing Sheets");
      const newSheets = await refreshSheets();
      setIsLoading(false);
      if (newSheets) {
        dispatch(setGoogleFileNames(newSheets));
        toggleSnackbar(dispatch, "complete", "Sheets Refreshed Successfully");
      }
      else {
        toggleSnackbar(dispatch, "error", "Error Refreshing Sheets");
      }

    }
    else {
      toggleSnackbar(dispatch, "error", "Login credentials error, please sign in again");
      dispatch(signOut());
    }

  };

  return (isSignedIn &&
    <Button
      type="short"
      startIcon={'refresh'}
      disabled={isLoading}
      onClick={onRefreshClick}
      label={`${isLoading ? "Refreshing..." : "Refresh Sheets"}`} />);
};

export default GoogleRefreshButton;
import { get } from 'aws-amplify/api';
import { getRandomQuestions } from '../functions/funFacts';
import { getJwtToken } from '../functions/useJwtToken';

export const getExampleQuestions = async () => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        const exampleQuestions = await (await get({
            apiName: 'MaesterV3API',
            path: '/api/v3/question-suggestions',
            options: { headers: { Authorization: jwtToken } }
        }).response).body.json();
        const allQuestions = exampleQuestions
            .map(e => e.suggested_question);
        const shuffledQuestions = allQuestions.sort(() => Math.random() - 0.5);
        const topQuestions = shuffledQuestions.slice(0, 4);
        const randomQuestions = getRandomQuestions(4 - topQuestions.length);
        const questions = [...topQuestions, ...randomQuestions];
        return questions;
    }
    catch (error) {
        console.error("Failed to get suggested questions, backfilling with presets until there are enough active questions", error);
        const randomQuestions = getRandomQuestions(4);
        return randomQuestions;
    }
}
import React, { useEffect, useState } from 'react';
import Button from '../../../Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/message.css';
import { findUserMessageByRunId } from '../../../../state/slices/messagesSlice';
import { answerValidation, getResponse } from '../../../../api/Ask';
import { toggleSnackbar } from '../../../Snackbar/Snackbar';
import { setMessageTree } from '../../../../state/slices/messagesSlice.jsx';

const ValidateButton = (props) => {
    const dispatch = useDispatch();
    const { messageObject, validationList, setValidationList, showValidation, setShowValidation, validationStatus, setValidationStatus, activeGroup, setActiveGroup } = props;
    const { run_id } = messageObject;
    const { user } = useSelector(state => state.utilities);
    const { currentThreadID } = useSelector(state => state.threads);
    const { messageTree } = useSelector(state => state.messages);
    const { selectedModels } = useSelector(state => state.modelSelector);
    const modelsToSend = selectedModels.length === 0
        ? process.env.REACT_APP_POSSIBLE_MODELS.split(',').slice(0, 4)
        : selectedModels;
    let userMessage = findUserMessageByRunId(messageTree, run_id);
    useEffect(() => {
        checkAlreadyValidated();
    }, []);


    const parseGroupsAndTraces = (validatedResponses) => {
        const groups = {};
        let baselineGroup, baselineID;
        validatedResponses.forEach(message => {
            if (message.metadata && message.metadata.consensus_group !== null) {
                if (message.metadata.is_selected_assistant) {
                    baselineGroup = message.metadata.consensus_group;
                    baselineID = message.message_id;
                }
                if (!groups[message.metadata.consensus_group]) {
                    groups[message.metadata.consensus_group] = [];
                }
                //to get all messages, move the below line outside the if statement
                groups[message.metadata.consensus_group].push({
                    ...message,
                    type: message.type ?? "text"
                });
            }
        });

        // Set validation status and check overall consensus
        if (baselineGroup !== null) {
            // setValidationMessage(groups[baselineGroup].length + "/" + validatedResponses.length);
            if (groups[baselineGroup].length === validatedResponses.length) {
                setValidationStatus("agree");
            } else if (groups[baselineGroup].length === 1) {
                setValidationStatus("disagree");
            } else {
                setValidationStatus("mixed");
            }
        };

        return { groups, baselineGroup, baselineID };
    }

    // Create list of responses from validation. 
    // First should be the original message, second should be the assistant recommendation
    // We could be doing more here to show which messages agree with one another
    const createValidationList = (groups, baselineGroup, baselineID) => {
        const tempValidationList = [];
        for (const group in groups) {
            if (group === baselineGroup) {
                tempValidationList.push(groups[group].find(message => message.message_id === baselineID));
            } else {
                // If there is a best answer, use that. Otherwise, use the first message in the group
                const bestAnswer = groups[group].find(message => message.metadata.is_best_answer);
                if (bestAnswer) {
                    tempValidationList.push(bestAnswer);
                } else {
                    tempValidationList.push(groups[group][0]);
                }
                // tempValidationList.push(...groups[group]);
            }
        }
        // Make sure the original message is first, and if there is a best answer (that is different than the original), put that second.
        tempValidationList.sort((a, b) => {
            if (a.message_id === baselineID) return -1;
            if (b.message_id === baselineID) return 1;
            if (a.metadata.is_best_answer && a.message_id !== baselineID) return -1;
            if (b.metadata.is_best_answer && b.message_id !== baselineID) return 1;
            return 0;
        });
        return tempValidationList;
    }

    const checkAlreadyValidated = () => {
        try {
            const allChildrenValid = userMessage.children.length === modelsToSend.length &&
                userMessage.children.every(child => child.metadata.consensus_group !== undefined && child.metadata.reasoning_trace !== undefined);
            if (allChildrenValid) {
                const { groups, baselineGroup, baselineID } = parseGroupsAndTraces(userMessage.children);
                setValidationList(createValidationList(groups, baselineGroup, baselineID));
                return true;
            }
        } catch (error) {
            console.error("getting validation", error);
            return false;
        }
        return false;
    }

    const handleValidate = async () => {
        setValidationStatus("validating");
        //continuously poll message tree until we get all messages
        let startedValidation = false;
        let messageResponseBody;
        const MAX_ATTEMPTS = 60;
        const MIN_WAIT = 3000;  // 3 seconds
        let attempts = 0;
        try {
            while (attempts < MAX_ATTEMPTS) {
                attempts++;
                const startTime = Date.now();
                try {
                    messageResponseBody = await getResponse({
                        thread_id: currentThreadID,
                        run_id: run_id,
                        models: modelsToSend,
                        parent_message_id: userMessage.message_id
                    });
                } catch (error) {
                    console.error("error getting message response body", error)
                    console.log("Trying one more time...")

                    messageResponseBody = await getResponse({
                        thread_id: currentThreadID,
                        run_id: run_id,
                        models: modelsToSend,
                        parent_message_id: userMessage.message_id
                    });
                }
                const elapsedTime = Date.now() - startTime;
                userMessage = findUserMessageByRunId(messageResponseBody.messages, run_id);
                //once we have all messages
                if (messageResponseBody.status === "completed" && userMessage && userMessage.children) {
                    dispatch(setMessageTree(messageResponseBody.messages));
                    // check if we have a consensus group (ie. validation is done)
                    if (userMessage.children.every(child => ![undefined, null].includes(child.metadata.consensus_group))) {
                        const { groups, baselineGroup, baselineID } = parseGroupsAndTraces(userMessage.children);
                        setValidationList(createValidationList(groups, baselineGroup, baselineID));
                        setShowValidation(true);
                        break;
                    }
                    // otherwise, start validation process (should only happen once, which is what the startedValidation flag is for)
                    else if (!startedValidation) {
                        const validationResponse = await answerValidation({ currentThreadID, runID: run_id, organization: user.organization })
                        startedValidation = true;
                    }
                    // go back to top of loop until getResponse
                }
                await new Promise(r => setTimeout(r, Math.max(0, MIN_WAIT - elapsedTime)));
            }

        } catch (error) {
            console.error("error sending message", error)
            toggleSnackbar(dispatch, "error", "Error validating message. Please refresh and try again.");
        }

    }

    let startIcon = "";
    let label = "";
    let iconStyle = {};
    let labelStyle = {};

    switch (validationStatus) {
        case "validating":
            startIcon = "sync";
            iconStyle = { animation: "rotate 2s ease-in-out infinite", color: "var(--primary-color)" };
            label = "Validating...";
            labelStyle = { color: "var(--primary-color)" };
            break;
        case "agree":
            startIcon = "check_circle";
            break;
        case "disagree":
        case "mixed":
            startIcon = "info";
            break;
        default:
            startIcon = "radio_button_unchecked";
            iconStyle = { color: "var(--primary-color)" };
            label = "Validate";
            labelStyle = { color: "var(--primary-color)" };
            break;
    }

    return (
        <>
            <Button type={[null, "validating"].includes(validationStatus) ? "medium" : "icon-medium"}
                startIcon={startIcon}
                label={label}
                asLabel={validationStatus === "validating"}
                labelStyle={labelStyle}
                iconStyle={iconStyle}
                onClick={() => { validationStatus === null ? handleValidate() : setShowValidation(v => !v) }} />
            {![null, "validating"].includes(validationStatus) && <Button type={"icon-medium"}
                startIcon={showValidation ? "expand_less" : "expand_more"}
                label={label}
                labelStyle={labelStyle}
                iconStyle={iconStyle}
                onClick={() => { setShowValidation(v => !v) }} />
            }
        </>
    );


};

export default ValidateButton;
import React from 'react'
import { setDetailClass } from '../../state/slices/sidemenuSlice';
import './style/detail.css';

import { openPopup } from '../Popup/Popup';

import { SettingsDetail } from './DetailTypes/SettingsDetail';
import { ManageDataDetail } from './DetailTypes/ManageDataDetail';
import { HistoryDetail } from './DetailTypes/HistoryDetail';
import { IntakeDetail } from './DetailTypes/IntakeDetail';
import { FormattingDetail } from './DetailTypes/FormattingDetail';
import { KeywordsDetail } from './DetailTypes/KeywordsDetail';
import { PerFileContextDetail } from './DetailTypes/ManageDataTypes/PerFileContextDetail';

export const openDetail = (dispatch, detailClass) => {
    dispatch(setDetailClass(detailClass));
    return;
};

export const closeDetail = (dispatch) => {
    dispatch(setDetailClass(null));
    return;
};


// For Reference: 
// const { detailClass, activeButton, isSidemenuCollapsed, sidemenuSize } = useSelector(state => state.sidemenu);
//Here is a detail structure, not in here because we want to be able to slide them in and out and it seems confusing to do this in another way
{/* <div className={`detail ${detailClass ? detailClass : 'collapsed'}`}>
        <div className="content">        
            [YOUR DETAIL HERE]
        </div>
        <div className="submit-buttons-container">
            [YOUR BUTTONS HERE]
        </div>
            <div className="detail-footer">
                Maester is constantly improving.
                <span
                    className="feedback-link"
                    onClick={() => openPopup(dispatch, "feedback")}
                >
                    leave feedback anytime
                </span>
            </div>
        </div> */}
function Detail() {
    return ([<SettingsDetail key={"settings"} />,
    <ManageDataDetail key={"manage-data"} />,
    <PerFileContextDetail key={"file-context"} />,
    <HistoryDetail key={"history"} />,
    <IntakeDetail key={"intake"} />,
    <FormattingDetail key={"formatting"} />,
    <KeywordsDetail key={"keywords"} />]);

}

export const DetailFooter = (dispatch) => {
    return (
        <div className="detail-footer">
            Maester is constantly improving.
            <span
                className="feedback-link"
                onClick={() => openPopup(dispatch, "feedback")}
            >
                leave feedback anytime
            </span>
        </div>
    );
}

export default Detail


import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { trendingTopics } from "../../functions/trendingTopics";
import { setPresetMessage } from '../../state/slices/messagesSlice';
import './style/trending-topics.css';
import { toggleSnackbar } from "../Snackbar/Snackbar";

function TrendingTopics() {
    const dispatch = useDispatch();
    const [selectedTT, setSelectedTT] = useState(null);
    const [prompts, setPrompts] = useState([])
    const [sortedTopics, setSortedTopics] = useState([]);
    const [sortedPrompts, setSortedPrompts] = useState([]);
    const { detailClass, sidemenuSize } = useSelector(state => state.sidemenu);
    const { isSendDisabled } = useSelector(state => state.utilities);

    // Eventually, this will be a get request. As such, makes sense to keep in useeffect to remember. no need to redo anyways
    useEffect(() => {
        const topics = Object.keys(trendingTopics).sort(() => Math.random() - 0.5).slice(0, 5);
        while (prompts.length < 5) {
            prompts.push(null);
        }
        setSortedTopics(topics);
    }, []);

    //selectedTT is selected trending topic
    const handleTTClick = (topic) => {
        setSelectedTT(topic);
        const prompts = Object.keys(trendingTopics[topic]).sort(() => Math.random() - 0.5).slice(0, 5);
        while (prompts.length < 5) {
            prompts.push(null);
        }
        setSortedPrompts(prompts);
    }

    function handlePromptClick(topic) {
        const randomIndex = Math.floor(Math.random() * trendingTopics[selectedTT][topic].length);
        if (isSendDisabled) {
            return;
        }
        dispatch(setPresetMessage(trendingTopics[selectedTT][topic][randomIndex]));
        setTimeout(() => {
            setSelectedTT(null);
        }, 500);

    }


    return (
        <div className={`trending-questions ${isSendDisabled ? "disabled" : ""} ${(detailClass || sidemenuSize === "small") ? "hidden" : ""}`}>
            <div className={`section-header`}>
                <i className={`material-icons-outlined`}> trending_up </i>
                <div className={`text-header`}>
                    {selectedTT ?
                        <>
                            <div className={`todays`}>  {selectedTT} </div>
                            <div className={`subtitle`}>  Select Popular Prompts </div>
                        </>
                        :
                        <>
                            <div className={`todays`}>  Today's </div>
                            <div className={`trending-topics`}> Trending Topics </div>
                        </>
                    }
                </div>
            </div>
            <div className={`topic-section`}>
                {sortedPrompts.map((prompt, index) => (
                    <div key={index} data-index={index} className={`list-prompt ${prompt === null ? "invisible" : ""} ${selectedTT === null ? "hidden" : ""}`} onClick={() => handlePromptClick(prompt)} style={{
                        transition: `all 0.4s, transform 0.4s ${index * 0.05}s, opacity 0.4s ${index * 0.05}s`,
                    }}>
                        <div className={`label prompt`} >{prompt}</div>
                        <i className={`material-icons-outlined`}> add </i>
                    </div>
                ))}
                {sortedTopics.map((topic, index) => (
                    <div key={index} data-index={index} className={`list-topic ${topic === null ? "invisible" : ""} ${selectedTT ? "hidden" : ""}`} onClick={() => handleTTClick(topic)} style={{
                        transition: `all 0.4s, transform 0.4s ${index * 0.05}s, opacity 0.4s ${index * 0.05}s`,
                    }}>
                        <div className={`number`}>{index + 1}</div>
                        <div className={`divider`} />
                        <div className={`label`} >{topic}</div>
                        <i className={`material-icons-outlined`}> arrow_forward </i>
                    </div>
                ))}
            </div>
            <div className={`section-footer`}>
                <i className={`material-icons-outlined ${selectedTT ? "" : "fade-out"}`} onClick={() => setSelectedTT(null)}> keyboard_arrow_left </i>
                <div className={`description ${selectedTT ? "fade-out" : ""}`}> What other companies are discussing </div>
            </div>
        </div>
    )
}

export default TrendingTopics;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedModels, setTemperature } from '../../state/slices/modelSelectorSlice';
import './style/model-selector.css';

const ModelSelector = () => {
  const dispatch = useDispatch();
  const { selectedModels, temperature } = useSelector(state => state.modelSelector);
  const handleModelChange = (e) => {
    dispatch(setSelectedModels(e.target.value));
  };
  const handleTemperatureChange = (e) => {
    dispatch(setTemperature(parseFloat(e.target.value)));
  };
  const possibleModels = process.env.REACT_APP_POSSIBLE_MODELS.split(',');

  if (process.env.REACT_APP_ENV !== "dev" || possibleModels.length === 0) {
    return null;
  }

  return (
    <>
      <div>
        <h1 >Model Selection</h1>
        <select
          multiple
          value={selectedModels}
          onChange={handleModelChange}
          className="model-selector medium"
        >
          {possibleModels.map((model) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>
      </div>
      <div>
        <h1>Model Temperature</h1>
        <input
          type="number"
          className="temperature"
          value={temperature}
          step="0.1"
          min="0"
          max="2"
          onChange={handleTemperatureChange}
        />
      </div>
    </>
  );
};

export default ModelSelector;
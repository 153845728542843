import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style/chat.css";

import { setTheme, setAudio, setIsSendDisabled } from "../../state/slices/utilitiesSlice";
import { setUser } from "../../state/slices/utilitiesSlice";
import Sidemenu from "../../components/Sidemenu/Sidemenu";
import Snackbar from "../../components/Snackbar/Snackbar";
import Popup, { openPopup } from "../../components/Popup/Popup";

import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import MessagesPanel from "../../components/MessagesPanel/MessagesPanel";
import MessageInput from "../../components/MessageInput/MessageInput";
import { getContext } from "../../api/Explanation";
import { setKeywords, setChartFormatting, setGeneralContext, setColors } from "../../state/slices/dataIntakeSlice";

function Chat({ user }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const initialLoadRef = useRef(false);
  // Initial Loading
  useEffect(() => {
    const load = async () => {
      const contextPromise = getContext();
      const context = await contextPromise;
      if (context) {
        if (context.explanation) { dispatch(setGeneralContext(context.explanation)); }
        if (context.user_preferences) {
          const preferences = JSON.parse(context.user_preferences);
          if (preferences.colors) { dispatch(setColors(preferences.colors)); }
          if (preferences.keywords) { dispatch(setKeywords(preferences.keywords)); }
          if (preferences.chart_formatting) { dispatch(setChartFormatting(preferences.chart_formatting)); }
          if (preferences.theme !== null && localStorage.getItem('theme') === null) {
            document.documentElement.setAttribute('data-theme', preferences.theme);
            dispatch(setTheme(preferences.theme));
          }
          if (preferences.audio !== null && localStorage.getItem('audio') === null) {
            dispatch(setAudio(preferences.audio));
          }
        }
      }

      // Simulate a delay of at least 800ms, but only if other functions resolve before 800ms
      const timerPromise = new Promise(resolve => setTimeout(resolve, 800));
      await Promise.all([contextPromise, timerPromise]);
      setIsLoading(false);
    };

    if (user && dispatch && !initialLoadRef.current) {
      dispatch(setUser(user));
      load();
      initialLoadRef.current = true;
    }
  }, [user, dispatch]);

  // Retrieve theme from localStorage when the component mounts
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    const savedAudio = localStorage.getItem('audio');
    if (savedTheme) {
      dispatch(setTheme(savedTheme));
      document.documentElement.setAttribute('data-theme', savedTheme);
    }
    if (savedAudio !== null) {
      dispatch(setAudio(savedAudio === "true"));
    }
  }, [dispatch]);


  return (
    <div className="chat-container">
      <Sidemenu />
      <div className={`chat`}>
        <MessagesPanel />
        <MessageInput />
        <span className="chat-footer"> Maester can make mistakes. Check important info and&nbsp;<span className="primary" onClick={() => openPopup(dispatch, "feedback")} style={{ cursor: "pointer", textDecoration: "underline" }}>share feedback anytime </span> </span>
      </div>
      <Popup />
      <Snackbar />
      <LoadingOverlay isLoading={isLoading} />
    </div>
  );
}

export default Chat;
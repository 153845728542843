import { createSlice } from '@reduxjs/toolkit';

const utilitiesSlice = createSlice({
  name: 'utilities',
  initialState: {
    isSendDisabled: false,
    theme: 'light',
    audio: true,
    user: {}
  },
  reducers: {
    setIsSendDisabled: (state, action) => {
      state.isSendDisabled = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
      localStorage.setItem('theme', state.theme);
      document.documentElement.setAttribute('data-theme', state.theme);
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleAudio: (state) => {
      state.audio = !state.audio;
      localStorage.setItem('audio', state.audio);
    },
    setAudio: (state, action) => {
      state.audio = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    }
  },
});

export const { setIsSendDisabled, toggleTheme, setTheme, toggleAudio, setAudio, setUser } = utilitiesSlice.actions;
export default utilitiesSlice.reducer;
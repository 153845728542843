import { createSlice } from '@reduxjs/toolkit';

const dataIntakeSlice = createSlice({
  name: 'dataIntake',
  initialState: {
    keywords: {},
    colors: [],
    generalContext: '',
    chartFormatting: {
      title: {
        bold: false,
        underline: false,
        italicize: false,
        colors: false,
      },
      x_axis: {
        bold: false,
        underline: false,
        italicize: false,
        colors: false,
      },
      y_axis: {
        bold: false,
        underline: false,
        italicize: false,
        colors: false,
      },
      gridlines: { enabled: false },
    }
  },
  reducers: {
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setColors: (state, action) => {
      state.colors = action.payload;
    },
    setPrimaryColor: (state, action) => {
      state.colors[0] = action.payload;
    },
    setSecondaryColor: (state, action) => {
      state.colors[1] = action.payload;
    },
    setTertiaryColor: (state, action) => {
      state.colors[2] = action.payload;
    },
    setGeneralContext: (state, action) => {
      state.generalContext = action.payload;
    },
    setChartFormatting: (state, action) => {
      state.chartFormatting = action.payload;
    }
  },
});

export const {
  setKeywords,
  setColors,
  setPrimaryColor,
  setSecondaryColor,
  setTertiaryColor,
  setGeneralContext,
  setChartFormatting,
  toggleChartFormatting,
} = dataIntakeSlice.actions;
export default dataIntakeSlice.reducer;
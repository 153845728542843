import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../../style/detail.css';
import { DetailFooter } from '../../Detail';
import Button from '../../../Button/Button';
import { setDetailClass } from '../../../../state/slices/sidemenuSlice';
import { getContext, setContext } from "../../../../api/Explanation.jsx"
import { setActiveContextFile } from '../../../../state/slices/contextSlice.jsx';
import { toggleSnackbar } from '../../../Snackbar/Snackbar.jsx';

export function PerFileContextDetail() {
    const dispatch = useDispatch();
    const [activeContext, setActiveContext] = useState("");
    const detailClass = useSelector(state => state.sidemenu.detailClass);
    const filename = useSelector(state => state.context.activeContextFile);
    let displayName, displaySheetName, displayTabName, snackbarName;
    const [isLoading, setIsLoading] = useState(false);

    displayName = filename.startsWith("googlesheets_") ? filename.replace("googlesheets_", "").replace("_", " ").replace(".csv", "") : filename;
    [displaySheetName, displayTabName] = filename.startsWith("googlesheets_") ? displayName.split('-MAESTERTABNAME-') : [null, null]
    snackbarName = displayTabName && displaySheetName ? displayTabName : filename

    useEffect(() => {
        if (filename) {
            handleGetContext();
        }
    }, [filename]);

    const handleGetContext = async () => {
        setIsLoading(true);
        toggleSnackbar(dispatch, "info", "Loading context for " + snackbarName);
        try {
            const context = await getContext([filename]);
            if (context) {
                setActiveContext(context[filename]);
                setIsLoading(false);
                toggleSnackbar(dispatch, "complete", "Context loaded for " + snackbarName);
            }
        } catch (error) {
            toggleSnackbar(dispatch, "error", "Failed to load context for " + snackbarName);
            dispatch(setDetailClass("manage-data"));
            dispatch(setActiveContextFile(""));
            setActiveContext("");
        }
    };

    const submitContext = async () => {
        toggleSnackbar(dispatch, "info", "Updating context for " + snackbarName);
        const responseBody = await setContext(filename, activeContext);
        if (responseBody) {
            toggleSnackbar(dispatch, "complete", "Context updated for " + snackbarName);
            dispatch(setDetailClass("manage-data"));
            dispatch(setActiveContextFile(""));
            setActiveContext("");
        }
        else {
            toggleSnackbar(dispatch, "error", "Failed to update context for " + snackbarName);
        }

    }

    return (
        <div className={`detail ${detailClass === "file-context" ? "showing" : 'hidden'}`}>
            <div className="content">
                <h1>Edit Context for</h1>
                <Button
                    asLabel
                    google={displayTabName && displaySheetName}
                    startIcon={displayTabName && displaySheetName ? null : "edit"}
                    type="long"
                    label={displayTabName ? displayTabName : displayName}
                    subtitle={(displayTabName && displaySheetName) ? displaySheetName : null}
                />
                <textarea
                    type="text"
                    className={`${isLoading ? "loading-context" : ""} context`}
                    placeholder={`${isLoading ?
                        "Loading..."
                        :
                        "Use this window to give Maester additional context about your connected data. This space is useful for things like internal definitions, formulas, or other general information that you may want to ask about."}`}
                    value={activeContext}
                    onChange={(e) => setActiveContext(e.target.value)}
                />
                <div className="submit-buttons-container">
                    <Button
                        type="action"
                        label="Cancel"
                        disabled={isLoading}
                        onClick={() => {
                            dispatch(setActiveContextFile(""));
                            dispatch(setDetailClass("manage-data"));
                        }}
                    />
                    <Button
                        type="action"
                        label="Submit"
                        disabled={isLoading}
                        onClick={() => submitContext()}
                    />
                </div>
            </div>
            {DetailFooter(dispatch)}
        </div>
    );
};
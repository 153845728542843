import { get, put } from 'aws-amplify/api';
import { getJwtToken } from "../functions/useJwtToken"

//Include Get Context, Set Context, Per File Context

export const getContext = async (contextList = null) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        const queryParams = {}
        if (contextList) {
            contextList.forEach(file => {
                queryParams.files = file;
            });
        }
        const returnedContext = await (await get({
            apiName: 'MaesterV3API',
            path: '/api/v3/explanation',
            options: {
                headers: { Authorization: jwtToken },
                queryParams: queryParams
            }
        }).response).body.json();
        return returnedContext;
    } catch (error) {
        console.error("Failed to get explanation. If this is a new organization, be sure to create an organization level context.", error);
        return null;
    }
}

export const setContext = async (fileToUpdate, context) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        let body = { explanation: context }
        if (fileToUpdate !== 'Organization Level Context') { body.file = fileToUpdate };
        const response = await put({
            apiName: 'MaesterV3API',
            path: '/api/v3/explanation',
            options: {
                headers: { Authorization: jwtToken },
                body: body
            }
        }).response;
        const responseBody = await response.body.json();
        return responseBody;
    } catch (error) {
        console.error("Failed to update context", error);
        return null;
    }
};

export const setPreferences = async (preferences) => {
    const jwtToken = await getJwtToken();
    if (!jwtToken) {
        console.error("JWT Token Fetch Error")
        return null
    }
    try {
        let body = { user_preferences: preferences }
        const response = await put({
            apiName: 'MaesterV3API',
            path: '/api/v3/explanation',
            options: {
                headers: { Authorization: jwtToken },
                body: body
            }
        }).response;
        const responseBody = await response.body.json();
        return responseBody;
    } catch (error) {
        console.error("Failed to update context", error);
        return null;
    }
};
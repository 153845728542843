import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsSendDisabled } from '../../../../../state/slices/utilitiesSlice';
import { deleteGoogleFileName } from '../../../../../state/slices/googleSlice';
import { handleDeleteFile } from '../../../../../api/Files';
import { toggleSnackbar } from '../../../../Snackbar/Snackbar';
import { getAllThreads } from '../../../../../api/Ask';
import { setThreads } from '../../../../../state/slices/threadsSlice.jsx';
import Button from '../../../../Button/Button';
import { setDetailClass } from '../../../../../state/slices/sidemenuSlice.jsx';
import { setActiveContextFile } from '../../../../../state/slices/contextSlice.jsx';
import GoogleAuthButton from './GoogleAuthButton.jsx';
import GoogleRefreshButton from './GoogleRefreshButton.jsx';
import GoogleUploadButton from './GoogleUploadButton.jsx';


// const response = await (await [Function]).response;
// const responseBody = await response.body.json();
// console.log("responseBody", responseBody);
export function GoogleDetail({ initialActive, active }) {
    const dispatch = useDispatch();
    const { googleFileNames, isSignedIn, userId } = useSelector(state => state.google);

    const deleteFile = async (fileNameToDelete) => {
        const displayName = fileNameToDelete.startsWith("googlesheets_") ? fileNameToDelete.replace("googlesheets_", "").replace("_", " ").replace(".csv", "") : fileNameToDelete;
        const [displaySheetName, displayTabName] = displayName.split('-MAESTERTABNAME-');
        dispatch(setIsSendDisabled(true));
        try {
            toggleSnackbar(dispatch, "info", "Disconnecting file: " + displaySheetName + ": " + displayTabName);
            const responseBody = await handleDeleteFile(fileNameToDelete);
            if (responseBody.message.includes("successfully deleted")) {
                dispatch(deleteGoogleFileName(fileNameToDelete));
                toggleSnackbar(dispatch, "complete", "File " + displaySheetName + ": " + displayTabName + " successfully disconnected");
                var t = await getAllThreads();
                dispatch(setThreads(t));
            } else {
                toggleSnackbar(dispatch, "error", "Error disconnecting " + displaySheetName + ": " + displayTabName + ". Please try again.");
            }
        } catch (error) {
            console.error(error);
            toggleSnackbar(dispatch, "error", "Error disconnecting " + displaySheetName + ": " + displayTabName + ". Please try again.");
        } finally {
            dispatch(setIsSendDisabled(false));
            return;
        }
    };


    return (
        <div className={`datasource ${initialActive ? (active ? "" : "hidden") : "initial-hidden"}`}>
            <h1>Google Sheet Options</h1>
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                <GoogleAuthButton />
                <GoogleUploadButton />
                <GoogleRefreshButton />


            </div>
            {googleFileNames.length > 0 ? <h1>Connected Sheets</h1> : null}
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {googleFileNames.map((filename, index) => {
                    const displayName = filename.startsWith("googlesheets_") ? filename.replace("googlesheets_", "").replace("_", " ").replace(".csv", "") : filename;
                    const [displaySheetName, displayTabName] = displayName.split('-MAESTERTABNAME-');
                    return (
                        <Button
                            google
                            key={index}
                            type="long"
                            label={displayTabName}
                            subtitle={displaySheetName}
                            endIcon1="link_off"
                            endFunction1={() => {
                                deleteFile(filename);
                            }}
                            endIcon2="edit"
                            endFunction2={() => {
                                dispatch(setActiveContextFile(filename));
                                dispatch(setDetailClass("file-context"));
                            }}
                        />
                    )
                })}
            </div>
        </div>


    )

}
import { signOut } from "aws-amplify/auth";
import { toggleSnackbar } from "../components/Snackbar/Snackbar";

const signOutFn = async (dispatch, navigate) => {
    try {
        await signOut();
        navigate('/');
    } catch (error) {
        toggleSnackbar(dispatch, "error", "Error logging out. Please try again");
        console.log('Error logging out: ', error);
    }
};

export default signOutFn;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSnackbar } from '../../../Snackbar/Snackbar';
import { connectHubspot, handleHubspotRefresh } from '../../../../api/Hubspot/HubSpotExport.jsx';
import { getHubSpotObjectToFields, handleHubspotSubmit } from '../../../../api/Hubspot/HubSpotFields.jsx';
import Button from '../../../Button/Button';
import Chip from '../../../Chip/Chip';
import '../../style/hubspot-search-bar.css'

export function HubspotDetail({ initialActive, active }) {
    const dispatch = useDispatch();
    const [hubspotSelectedObjects, setHubspotSelectedObjects] = useState([]);
    const [hubspotConnectionStatus, setHubspotConnectionStatus] = useState("fail");
    const [hubspotSelectedFields, setHubspotSelectedFields] = useState({});
    const [hubspotFields, setHubspotFields] = useState("fail");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedFiltered, setSelectedFiltered] = useState(false);


    const addHubspotSelectedObject = (object) => {
        setHubspotSelectedObjects([...hubspotSelectedObjects, object]);
    };
    const removeHubspotSelectedObject = (object) => {
        setHubspotSelectedObjects(hubspotSelectedObjects.filter(o => o !== object));
    };

    useEffect(() => { connectToHubspot(); }, []);

    const connectToHubspot = async () => {
        setHubspotConnectionStatus("loading");
        try {
            const connected = await connectHubspot();
            // console.log("connected to hubspot");
            if (connected) {
                setHubspotConnectionStatus("success");
                const [hfields, sfields] = await getHubSpotObjectToFields();
                setHubspotFields(hfields);
                setHubspotSelectedFields(sfields);
            }
            else {
                setHubspotConnectionStatus("fail");
                console.error("Failed to connect to Hubspot", connected);
            }
        } catch (error) {
            setHubspotConnectionStatus("fail");
            console.error("Failed to connect to Hubspot", error);
        }
    };





    const renderHubspotObjects = () => {
        return (Object.keys(hubspotFields)
            .filter(object => Array.isArray(hubspotFields[object])) // Filter out non-array fields
            .map((object, index) => {
                let selected = hubspotSelectedObjects.includes(object);
                return (
                    <Button
                        key={"h-object-" + index}
                        type="small"
                        label={object.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}
                        active={selected}
                        onClick={() => {
                            if (selected) {
                                removeHubspotSelectedObject(object);
                            } else {
                                addHubspotSelectedObject(object);
                            }
                        }} />
                );
            }));
    };

    const renderHubspotProperties = () => {
        const propertyList = [];
        const handleFieldChange = (object, field) => {
            setHubspotSelectedFields(selectedFields => ({
                ...selectedFields,
                [object]: {
                    ...(selectedFields[object] || {}),
                    [field]: !(selectedFields[object] && selectedFields[object][field])
                }
            }));
        }
        const searchElement = (
            <div key="search-key1" style={{ display: 'flex', flexDirection: "row", width: "100%" }} className={`${(hubspotSelectedObjects.length > 0) ? "" : "hidden"}`}>
                <form key="search-key2" className={`hubspot-search-bar`}>
                    <input type="text" placeholder="Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                </form>
            </div>
        );

        //Filter out non-array fields, then iterate over what's left
        Object.entries(hubspotFields).filter(([obj]) => Array.isArray(hubspotFields[obj])).forEach(([currentObject, properties]) => {
            //If the object is not selected, don't render its properties. if no object is selected, render all properties
            if (hubspotSelectedObjects.length > 0 && !(hubspotSelectedObjects.includes(currentObject))) return;
            const filteredFields = selectedFiltered ?

                Object.entries(hubspotSelectedFields[currentObject])
                    .filter(([key, value]) => value === true)
                    .map(([key, value]) => key).filter(property =>
                        property.toLowerCase().includes(searchQuery.toLowerCase())
                    )

                :

                hubspotFields[currentObject].filter(property =>
                    property.toLowerCase().includes(searchQuery.toLowerCase())
                );

            filteredFields.forEach((field, index) => {
                let selected = hubspotSelectedFields[currentObject] && hubspotSelectedFields[currentObject][field] ? "selected" : ""
                propertyList.push(
                    <Chip key={index + currentObject}
                        type='long'
                        tooltip={field}
                        active={selected}
                        label={field}
                        subtitle={currentObject.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}
                        onClick={() => handleFieldChange(currentObject, field)}
                        startIcon={selected ? "check_circle" : "add_circle_outline"} />
                );
            });
        });
        return (
            <>
                {searchElement}
                <div className='hubspot-properties'>
                    {propertyList}
                </div>
            </>
        );
    };


    return (
        <div className={`datasource ${initialActive ? (active ? "" : "hidden") : "initial-hidden"}`}>
            <h1>Hubspot Data Source Options</h1>
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                <Button
                    hubspot
                    type="short"
                    label={hubspotConnectionStatus === "fail" ? "Click to Connect" :
                        hubspotConnectionStatus === "loading" ? "Connecting..." :
                            hubspotConnectionStatus === "success" ? "Connected" : ""}
                    onClick={() => { if (hubspotConnectionStatus === "fail") (window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=e5220a0b-bcdd-4b12-b034-1b80a2ff9bd1&redirect_uri=${window.location.origin + '/hubspot-callback'}&scope=tickets%20sales-email-read%20crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.owners.read%20crm.export`); }} />
                <Button
                    type="short"
                    startIcon={"refresh"}
                    disabled={hubspotConnectionStatus !== "success"}
                    label={`Refresh Data`}
                    onClick={() => {
                        toggleSnackbar(dispatch, "info", "Refreshing data, this may take a few minutes", "loading");
                        handleHubspotRefresh()
                        setTimeout(() => {
                            toggleSnackbar(dispatch, "complete", "Refresh complete!");
                        }, 180000);
                    }} />
                <Button
                    type="short"
                    startIcon={"arrow_circle_up"}
                    disabled={hubspotConnectionStatus !== "success"}
                    label={`Update Selection`}
                    onClick={async () => {
                        toggleSnackbar(dispatch, "info", "Updating Hubspot Properties");
                        try {
                            const responseBody = await handleHubspotSubmit(hubspotSelectedFields);
                            if (responseBody === "Successfully updated fields") {
                                toggleSnackbar(dispatch, "complete", "Successfully updated Hubspot Properties!");
                            }
                        } catch (err) {
                            toggleSnackbar(dispatch, "error", "Failed to update Hubspot Properties. Reconnecting to Hubspot");
                            connectToHubspot();
                            console.error("Failed to update Hubspot Properties", err);
                        }
                    }
                    }
                />
            </div>
            <h1>Filter by</h1>
            <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", flexWrap: "wrap", width: "100%" }}>
                {renderHubspotObjects()}
                <Button type='small'
                    label="Selected"
                    tooltip="Filter by Selected"
                    active={selectedFiltered}
                    onClick={() => { setSelectedFiltered(selectedFiltered => !selectedFiltered) }} />
            </div>
            <h1>Properties</h1>
            {renderHubspotProperties()}
        </div>
    )

}


import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../style/detail.css';
import { HubspotDetail } from './ManageDataTypes/HubspotDetail';
import { GoogleDetail } from './ManageDataTypes/GoogleDetail/GoogleDetail.jsx';
import { UploadedDataDetail } from './ManageDataTypes/UploadedDataDetail';

import { DetailFooter } from '../Detail.jsx';
import { openPopup } from '../../Popup/Popup.jsx';
import Button from '../../Button/Button';


export function ManageDataDetail() {
    const dispatch = useDispatch();
    const [activeDatasource, setActiveDatasource] = useState(null);
    const { detailClass, isSidemenuCollapsed } = useSelector(state => state.sidemenu);
    const [visitedDatasources, setVisitedDatasources] = useState(new Set());

    //Aesthetic, so fadeout works properly
    useEffect(() => {
        if (!["manage-data", "hubspot", "google", "uploaded"].includes(detailClass)) {
            setVisitedDatasources(new Set().add(activeDatasource));
        }
    }, [detailClass]);

    return (
        <div className={`detail ${detailClass === "manage-data" ? detailClass : 'hidden'}`}>
            <div className="content">
                <h1>Manage Data</h1>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: '100%' }}>
                    <Button
                        hubspot
                        type="short"
                        label={`Hubspot`}
                        active={activeDatasource === "hubspot"}
                        onClick={() => { setActiveDatasource("hubspot"); setVisitedDatasources(prev => new Set(prev).add("hubspot")); }} />
                    <Button
                        type="short"
                        startIcon={"folder_open"}
                        label={`Uploaded Files`}
                        active={activeDatasource === "uploaded"}
                        onClick={() => { setActiveDatasource("uploaded"); setVisitedDatasources(prev => new Set(prev).add("uploaded")); }} />
                    <Button google type="short" label={`Google Sheets`} active={activeDatasource === "google"} onClick={() => { setActiveDatasource("google"); setVisitedDatasources(prev => new Set(prev).add("google")); }} />
                    <Button type="short" startIcon={"add"} label={`Request Source`} onClick={() => openPopup(dispatch, "request-source")} />

                </div>
                <div>
                    <HubspotDetail initialActive={visitedDatasources.has("hubspot")} active={detailClass === "manage-data" && activeDatasource === "hubspot"} />
                    <GoogleDetail initialActive={visitedDatasources.has("google")} active={detailClass === "manage-data" && activeDatasource === "google"} />
                    <UploadedDataDetail initialActive={visitedDatasources.has("uploaded")} active={detailClass === "manage-data" && activeDatasource === "uploaded"} />
                </div>
            </div>
            {DetailFooter(dispatch)}
        </div>
    );
};
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setIsPopupOpen, setPopupClass, setFeedback, setSelectedFiles } from '../../state/slices/popupSlice';
import './style/popup.css';

import { RequestSourcePopup } from './PopupTypes/RequestSourcePopup';
import { HelpPopup } from './PopupTypes/HelpPopup';
import { ContextPopup } from './PopupTypes/ContextPopup';
import { GooglePopup } from './PopupTypes/GooglePopup';
import { FeedbackPopup } from './PopupTypes/FeedbackPopup';
import { ThumbsPopup } from './PopupTypes/ThumbsPopup';
import { FilePreviewPopup } from './PopupTypes/FilePreviewPopup';

export const openPopup = (dispatch, popupClass) => {
    dispatch(setPopupClass(popupClass));
    dispatch(setIsPopupOpen(true));
    return;
};

export const closePopup = (dispatch) => {
    dispatch(setIsPopupOpen(false));
    dispatch(setPopupClass("hidden"));
    dispatch(setFeedback(""));
    dispatch(setSelectedFiles([]));
    return;
};

// For Reference: 
// const { isPopupOpen, popupThumbsUp, popupClass, feedback, selectedFiles } = useSelector(state => state.popup);
function PopUp() {
    const dispatch = useDispatch();
    const { isPopupOpen, popupClass, } = useSelector(state => state.popup);
    let filter = isPopupOpen ? 'blur(2px)' : 'none';
    let backgroundColor = isPopupOpen ? 'rgba(0,0,0,0.2)' : 'transparent';
    let pointerEvents = !isPopupOpen ? 'none' : 'auto';
    let popup = null;
    switch (popupClass) {
        case 'google':
            popup = <GooglePopup />;
            break;
        case 'feedback':
            popup = <FeedbackPopup />;
            break;
        case 'request-source':
            popup = <RequestSourcePopup />;
            break;
        case 'thumbs-up':
            popup = <ThumbsPopup popupThumbsUp={true} />;
            break;
        case 'thumbs-down':
            popup = <ThumbsPopup popupThumbsUp={false} />;
            break;
        case 'context':
            popup = <ContextPopup />;
            break;
        case 'help':
            popup = <HelpPopup />;
            break;
        case 'preview-file':
            popup = <FilePreviewPopup />;
            break;
        default:
            popup = null;
    }

    return (popup &&
        <div onClick={() => closePopup(dispatch)} className={`popup-background ${isPopupOpen ? "" : "hidden"}`}>
            {popup}
        </div>
    );

}

export default PopUp

